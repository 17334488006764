import React from 'react';
import { useTranslation } from "react-i18next";
import { Accordion } from "@amzn/alchemy-components-react";
import { ValidationError } from "src/models/validation-error";
import { getTranslatedErrorMsg } from "src/common/util";

interface ValidationResultRowProps {
    excelRowNum: number,
    validationErrors: ValidationError[]
}

export const ValidationResultRow = (props: ValidationResultRowProps) => {
    const {t} = useTranslation();

    return (
        <tr>
            <td className="w-25">{props.excelRowNum}</td>
            <td className="w-75">
                <Accordion
                    header={`${props.validationErrors.length} ${t('validation-errors-expand-to-view-details')}`}
                    transparent
                >
                    <ul>
                        {props.validationErrors.map((error, i) => {
                            return (
                                <li key={i}>{getTranslatedErrorMsg(t, error)}</li>
                            );
                        })}
                    </ul>
                </Accordion>
            </td>
        </tr>
    );
}

export default ValidationResultRow;