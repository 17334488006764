import React, { useEffect, useState } from 'react'
import { Button } from '@amzn/alchemy-components-react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { BreadCrumbs } from 'src/components/breadcrumb'
import { SiteFilter } from 'src/components/site-filter'
import { NETWORK_ONLY, AFTX_3PL_TENANT_ID } from 'src/common/constants'
import { RenderPrincipalCards } from './render-principal-cards'
import { CardItem } from 'src/components/cards/card-item'
import { BASELINING_STATES, FMUIPageTypes, USER_TYPES, UserTypes } from "src/common/enums";
import { Principal } from "src/models/principal";
import { PageProps } from "../page-interface";
import { logger } from "src/logger";
import { RegisterMfaModal } from "src/components/modals/register-mfa-modal";
import { LIST_PRINCIPALS_QUERY } from "src/common/gql-operations";
import { RegisterMfaModalHooks } from "src/models/register-mfa-modal-hooks";
import { RemoveScopeModalHooks } from "src/models/remove-principal-scope-modal-hooks";
import { RemoveScopeModal } from "src/components/modals/remove-principal-scope-modal";
import { PrincipalCard } from "src/models/principal-card";
import { useAuth } from "src/components/auth/auth-provider";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { convertDateTimeToLocaleFormat } from "src/common/util";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'principal-list'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

/**
 * Component to create PrincipalList Page.
 */
export const PrincipalList = (props: PageProps) => {

    const auth = useAuth();
    const { t } = useTranslation()
    const locale = document.documentElement.lang;   // e.g. en-US

    // TODO: Check with UX on final mockups to see if we should provide baselining / userType filters here

    // TODO: listPrincipals allow 'lastBaselinedBefore' date in input check if we want to provide date filter

    const [getPrincipalScopesLoading, setGetPrincipalScopesLoading] = useState<boolean>(true);
    const [site, setSite] = useState<string>()
    const [scopeOptions, setScopeOptions] = useState<any>([]);
    const [principalCards, setPrincipalCards] = useState<Map<string, PrincipalCard>>(new Map());
    const [errorMessage, setErrorMessage] = useState<string | undefined>()

    const [registerMfaModalOpen, setRegisterMfaModalOpen] = useState<boolean>(false);
    const [registerMfaModalPrincipalArn, setRegisterMfaModalPrincipalArn] = useState<string>();
    const [registerMfaModalFullName, setRegisterMfaModalFullName] = useState<string>();

    const [removeScopeModalOpen, setRemoveScopeModalOpen] = useState<boolean>(false);
    const [removeScopeModalPrincipalArn, setRemoveScopeModalPrincipalArn] = useState<string>();
    const [removeScopeModalFullName, setRemoveScopeModalFullName] = useState<string>();
    const [removeScopeModalTenantId, setRemoveScopeModalTenantId] = useState<string>();
    const [removeScopeModalScope, setRemoveScopeModalScope] = useState<string>();
    const [removeScopeModalUserType, setRemoveScopeModalUserType] = useState<UserTypes>();

    const registerMfaModalHooks: RegisterMfaModalHooks = {
        setOpen: setRegisterMfaModalOpen,
        setPrincipalArn: setRegisterMfaModalPrincipalArn,
        setFullName: setRegisterMfaModalFullName
    }

    const removeScopeModalHooks: RemoveScopeModalHooks = {
        setOpen: setRemoveScopeModalOpen,
        setPrincipalArn: setRemoveScopeModalPrincipalArn,
        setFullName: setRemoveScopeModalFullName,
        setTenantId: setRemoveScopeModalTenantId,
        setScope: setRemoveScopeModalScope,
        setUserType: setRemoveScopeModalUserType
    }

    const [listPrincipals, { loading: listPrincipalsLoading, error, data }] = useLazyQuery(LIST_PRINCIPALS_QUERY, {
        fetchPolicy: NETWORK_ONLY,
    })

    useEffect(() => {
        props.setActivePage(FMUIPageTypes.PRINCIPAL_LIST);
        populateScopes();
    }, []);

    useEffect(() => {
        if (site) {
            callListPrincipals(site);
        }

    }, [site]);

    useEffect(() => {
        if (!listPrincipalsLoading && data) {
            const principalCards = new Map<string, PrincipalCard>();
            const principals: Principal[] = data.listPrincipals.principals;
            const tenantId = data.listPrincipals.tenantId;

            for (const principal of principals) {
                principalCards.set(principal.principalArn, {
                    items: getCardItems(tenantId, principal),
                    expanded: false,
                    fullName: `${principal.firstName} ${principal.lastName}`,
                    scope: principal.scope,
                    userType: principal.userType
                })
            }

            logger.info(`Users fetched successfully for siteId: ${site}, userTypes:${USER_TYPES},
                        baselineStates:${BASELINING_STATES}`)
            setPrincipalCards(principalCards);
            setErrorMessage(undefined)
            graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.SUCCESS', 1);
        }
    }, [data, t])

    useEffect(() => {
        if (error) {
            setPrincipalCards(new Map());
            let message = `${t('list-principals-error-fetching-users')}: ${site}, userTypes:${USER_TYPES},
                             baselineStates:${BASELINING_STATES}. ` + error
            setErrorMessage(message)
            logger.info(message, error)
            graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.ERROR', 1);
        }
    }, [error])

    const callListPrincipals = (site: string) => {
        const listPrincipalsInput = {
            tenantId: AFTX_3PL_TENANT_ID,
            scope: site,
            baselineStates: BASELINING_STATES,
            userTypes: USER_TYPES
        };
        logger.info('Calling listPrincipals.', listPrincipalsInput);
        graphqlClientMetricsPublisher.publishCounterMonitor('list-principals.INVOCATION', 1);
        listPrincipals({
            variables: {
                listPrincipalsInput: listPrincipalsInput
            }
        });
    }

    /**
     * Populates the filter with the scopes to which the current user has access.
     */
    const populateScopes = async() => {
        const scopes = await auth.getScopes();

        if (scopes && scopes.length > 0) {
            scopes.forEach(scope => scopeOptions.push({
                label: scope,
                value: scope
            }));
        } else {
            setErrorMessage(t('failed-to-get-sites-user-can-access'));
        }

        setScopeOptions(scopeOptions);
        setSite(scopes[0]); // default to first scope to which user has access
        setGetPrincipalScopesLoading(false);
    }

    /**
     * Gets the list of {@link CardItem} from the {@link Principal} object.
     *
     * @param tenantId The id of the tenant.
     * @param principal The principal from which to get the data for the card items.
     */
    const getCardItems = (tenantId: string, principal: Principal) => {
        return [
            {
                attributeName: t('user-arn'),
                attributeValue: principal.principalArn,
            },
            {
                attributeName: t('tenant-id'),
                attributeValue: tenantId,
            },
            {
                attributeName: t('user-type'),
                attributeValue: principal.userType,
            },
            {
                attributeName: t('first-name'),
                attributeValue: principal.firstName
            },
            {
                attributeName: t('last-name'),
                attributeValue: principal.lastName
            },
            {
                attributeName: t('site'),
                attributeValue: principal.scope,
            },
            {
                attributeName: t('last-baselining-date'),
                attributeValue: principal.lastBaseliningDate ?
                    convertDateTimeToLocaleFormat(locale, new Date(principal.lastBaseliningDate)) : ''
            },
            {
                attributeName: t('last-baselined-by-principal'),
                attributeValue: principal.lastBaselinedByPrincipal,
            }
        ]
    }

    /**
     * Expands all principal cards.
     *
     * @param principalCards The principal cards to expand.
     */
    const expandAllCards = (principalCards: Map<string, PrincipalCard>) => {
        for (const principalArn of principalCards.keys()) {
            const principalCard = principalCards.get(principalArn)!;
            principalCards.set(principalArn, {
                items: principalCard.items,
                expanded: true,
                fullName: principalCard.fullName,
                scope: principalCard.scope,
                userType: principalCard.userType
            });
        }

        setPrincipalCards(new Map(principalCards));
    }

    /**
     * Collapses all principal cards.
     *
     * @param principalCards The principal cards to collapse.
     */
    const collapseAllCards = (principalCards: Map<string, PrincipalCard>) => {
        for (const principalArn of principalCards.keys()) {
            const principalCard = principalCards.get(principalArn)!;
            principalCards.set(principalArn, {
                items: principalCard.items,
                expanded: false,
                fullName: principalCard.fullName,
                scope: principalCard.scope,
                userType: principalCard.userType
            });
        }

        setPrincipalCards(new Map(principalCards));
    }

    /**
     * Expands the principal card if it's collapsed or collapses the principal card if it's expanded.
     *
     * @param principalArn The principal arn of the principal card to expand or collapse.
     */
    const toggleExpand = (principalArn: string) => {
        const principalCard = principalCards.get(principalArn)!;
        principalCards.set(principalArn, {
            items: principalCard.items,
            expanded: !principalCard.expanded,
            fullName: principalCard.fullName,
            scope: principalCard.scope,
            userType: principalCard.userType
        });

        setPrincipalCards(new Map(principalCards));
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{ tag: t('users'), path: '/principal' }]} />
            </div>
            <div className="row b-background mx-0">
                <div className="col align-self-center title m-0 py-1">{t('users')}</div>
            </div>
            <div className="row b-background mx-0 align-items-center">
                <div className="col-lg p-1">
                    {(scopeOptions.length > 0) &&
                        <SiteFilter
                            sites={scopeOptions}
                            site={site}
                            setSite={setSite}
                            status={site ? '' : t('required')}
                        />
                    }
                </div>
                <div className="col-lg-auto d-flex p-1">
                    <Button
                        className="mr-1 dashboard-buttons d-none d-xl-block"
                        icon="showDetail"
                        iconPosition="right"
                        label={t('expand-all')}
                        onClick={() => expandAllCards(principalCards)}
                        id="expand-all"
                    />
                    <Button
                        className="dashboard-buttons mr-1 d-none d-xl-block"
                        icon="hideDetail"
                        iconPosition="right"
                        label={t('collapse-all')}
                        onClick={() => collapseAllCards(principalCards)}
                        id="collapse-all"
                    />
                    <Button
                        className="mr-1 d-lg-block d-xl-none"
                        icon="showDetail"
                        iconLabel={t('expand-all')}
                        onClick={() => expandAllCards(principalCards)}
                        id="expand-all-labelless"
                    />
                    <Button
                        className="mr-1 d-lg-block d-xl-none"
                        icon="hideDetail"
                        iconLabel={t('collapse-all')}
                        onClick={() => collapseAllCards(principalCards)}
                        id="collapse-all-labelless"
                    />
                </div>
            </div>
            <RenderPrincipalCards
                loading={getPrincipalScopesLoading || listPrincipalsLoading}
                errorMessage={errorMessage}
                principalCards={principalCards}
                toggleExpand={toggleExpand}
                registerMfaModalHooks={registerMfaModalHooks}
                removeScopeModalHooks={removeScopeModalHooks}
            />
            <RegisterMfaModal
                open={registerMfaModalOpen}
                setOpen={setRegisterMfaModalOpen}
                principalArn={registerMfaModalPrincipalArn!}
                setPrincipalArn={setRegisterMfaModalPrincipalArn}
                fullName={registerMfaModalFullName!}
                setFullName={setRegisterMfaModalFullName}
            />
            <RemoveScopeModal
                open={removeScopeModalOpen}
                setOpen={setRemoveScopeModalOpen}
                principalArn={removeScopeModalPrincipalArn!}
                setPrincipalArn={setRemoveScopeModalPrincipalArn}
                fullName={removeScopeModalFullName!}
                setFullName={setRemoveScopeModalFullName}
                tenantId={removeScopeModalTenantId!}
                setTenantId={setRemoveScopeModalTenantId}
                scope={removeScopeModalScope!}
                setScope={setRemoveScopeModalScope}
                userType={removeScopeModalUserType!}
                setUserType={setRemoveScopeModalUserType}
            />
        </div>
    )
}
