import React from "react";
import { useTranslation } from "react-i18next";
import { FallbackSpinner } from "src/components/fallback-spinner";
import { AlertBar } from "src/components/alert-bar";
import { AlertType, WorkflowState } from "src/common/enums";
import { SummaryCard } from "src/components/cards/summary-card";
import { WorkflowActionMenu } from "src/components/workflow-action-menu";
import { Icon } from "@amzn/alchemy-components-react";
import { WorkflowCard } from "src/models/workflow-card";

interface RenderWorkflowCardsProps {
    loading: boolean
    errorMessage?: string
    workflowCards: Map<string, WorkflowCard>
    toggleExpand: (workflowId: string) => void
}

export const RenderWorkflowCards = (renderWorkflowCardsProps: RenderWorkflowCardsProps) => {
    const {t} = useTranslation();

    const renderWorkflowStatus = (workflowState: WorkflowState) => {
        if (workflowState === WorkflowState.SUCCEEDED) {
            return (
                <>
                    <div id="summary-card-status-tag">{t('succeeded')}</div>
                    <Icon icon="checkSolid" className="ml-1" id="summary-card-status-icon"/>
                </>
            )
        } else if (workflowState === WorkflowState.FAILED) {
            return (
                <>
                    <div id="summary-card-status-tag">{t('failed')}</div>
                    <Icon icon="xSolid" className="ml-1" id="summary-card-status-icon"/>
                </>
            )
        } else {
            return (
                <>
                    <div id="summary-card-status-tag">{t('active')}</div>
                    <Icon icon="clock" className="ml-1" id="summary-card-status-icon"/>
                </>
            )
        }
    }

    if (renderWorkflowCardsProps.loading) {
        return <FallbackSpinner/>
    } else if (renderWorkflowCardsProps.errorMessage) {
        return <AlertBar id='workflows-alert-bar'
                         result={AlertType.error}
                         dismissible={false}
                         header={t('list-workflows-error-alert-header')}
                         message={renderWorkflowCardsProps.errorMessage}/>
    } else {
        let nodes: React.ReactNode[] = [];

        if (renderWorkflowCardsProps.workflowCards) {
            renderWorkflowCardsProps.workflowCards.forEach((workflowCard, workflowId) => {
                nodes.push(
                    <SummaryCard
                        key={workflowId}
                        cardKey={workflowId}
                        summaryCardHeader={workflowCard.header}
                        summaryCardItems={workflowCard.items}
                        actionComponent={<WorkflowActionMenu className="summary-card-action"/>}
                        statusComponent={renderWorkflowStatus(workflowCard.state)}
                        toggleExpand={renderWorkflowCardsProps.toggleExpand}
                        expanded={workflowCard.expanded}
                    />
                );
            });
        }

        return <div id="workflow-cards">{nodes}</div>;
    }
}