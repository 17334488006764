import { Cookies } from 'react-cookie'

const cookies = new Cookies()

/**
 * Access point for managing Cookies.
 */
export class CookiesManager {
    static setCookie(cookieName: string, cookieValue: string) {
        cookies.set(cookieName, cookieValue, {path: '/'})
    }

    static getCookie(cookieName: string) {
        return cookies.get(cookieName)
    }

    static removeCookie(cookieName: string){
        cookies.remove(cookieName, {path: '/'})
    }
}