import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonGroup } from "@amzn/alchemy-components-react";
import { logger } from "src/logger";
import { BreadCrumbs } from 'src/components/breadcrumb';
import { FMUIPageTypes, OnboardType } from "src/common/enums";
import { PageProps } from "src/pages/page-interface";

export const Onboard = (props: PageProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        props.setActivePage(FMUIPageTypes.ONBOARD);
    }, []);

    const redirectToSingleOrBulk = (e: any) => {
        const selection = e.target.value;

        switch (selection) {
            case OnboardType.SINGLE:
                logger.info('Redirecting to single user onboard page.');
                history.push({
                    pathname: '/onboard/single',
                });
                break;
            case OnboardType.BULK:
                logger.info('Redirecting to bulk user onboard page.');
                history.push({
                    pathname: '/onboard/bulk',
                });
                break;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{tag: t('onboard'), path: '/onboard'}]}/>
            </div>
            <div className="row mx-0 mb-0-5 b-background">
                <div className="col title m-0 py-1">{t('onboard-menu')}</div>
            </div>
            <div className="b-background">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <p className="mb-0 px-2 pt-2">{t('onboard-menu-question')}</p>
                    </div>
                </div>
                <div className="row mt-0 py-2">
                    <div className="col d-flex justify-content-center">
                        <ButtonGroup
                            id="onboard-buttons"
                            onChange={redirectToSingleOrBulk}
                            buttons={[
                                { id: 'single-onboard-button', label: t('single'), value: OnboardType.SINGLE },
                                { id: 'bulk-onboard-button', label: t('bulk'), value: OnboardType.BULK }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Onboard;