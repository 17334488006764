import React, { useState } from 'react'
import { Menu, Button } from '@amzn/alchemy-components-react'
import { useTranslation } from 'react-i18next'
import { RegisterMfaModalHooks } from "src/models/register-mfa-modal-hooks";
import { RemoveScopeModalHooks } from "src/models/remove-principal-scope-modal-hooks";

/**
 * Input parameters required to create Principal Action component.
 */
interface PrincipalActionProps {
  /**
   * ClassName to be used to render this Principal Action.
   */
  className: string

  /**
   * The unique identifier for the principal.
   * */
  principalArn: string

  /**
   * The full name of the principal.
   */
  fullName: string

  /**
   * The tenant id of the scope.
   */
  tenantId: string

  /**
   * The scope of the principal.
   */
  scope: string

  /**
   * The user type of the principal.
   */
  userType: string

  /**
   * The hooks for the Register MFA modal.
   */
  registerMfaModalHooks: RegisterMfaModalHooks

  /**
   * The hooks for the Remove Scope modal.
   */
  removeScopeModalHooks: RemoveScopeModalHooks
}

/**
 * Component to create Principal Action Dropdown.
 */
export const PrincipalAction = (principalActionProps: PrincipalActionProps) => {
  const { t } = useTranslation()

  const [menuIcon, setMenuIcon] = useState('caretDown')

  const initializeRegisterMfaModal = () => {
    principalActionProps.registerMfaModalHooks.setPrincipalArn(principalActionProps.principalArn);
    principalActionProps.registerMfaModalHooks.setFullName(principalActionProps.fullName);
    principalActionProps.registerMfaModalHooks.setOpen(true);
  }

  const initializeRemoveScopeModal = () => {
    principalActionProps.removeScopeModalHooks.setPrincipalArn(principalActionProps.principalArn);
    principalActionProps.removeScopeModalHooks.setFullName(principalActionProps.fullName);
    principalActionProps.removeScopeModalHooks.setOpen(true);
    principalActionProps.removeScopeModalHooks.setTenantId(principalActionProps.tenantId);
    principalActionProps.removeScopeModalHooks.setScope(principalActionProps.scope);
    principalActionProps.removeScopeModalHooks.setUserType(principalActionProps.userType);
  }

  const registerMfa = {
    label: t('register-mfa'),
    action: initializeRegisterMfaModal
  }

  const removePrincipalScope = {
    label: t('remove-scope'),
    action: initializeRemoveScopeModal
  }

  const actionMenuOptions = []
  actionMenuOptions.push(registerMfa)
  actionMenuOptions.push(removePrincipalScope)

  return (
    <Menu
      className={principalActionProps.className}
      id="principal-action-menu"
      triggerLabel={t('actions')}
      options={actionMenuOptions}
      onClose={() => setMenuIcon('caretDown')}
      onOpen={() => setMenuIcon('caretUp')}
    >
      <span slot="trigger">
        <Button icon={menuIcon} iconLabel={t('principal-action-menu')} iconPosition="right">
          {t('actions')}
        </Button>
      </span>
    </Menu>
  )
}
