import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Icon, Input, Modal, Spinner } from '@amzn/alchemy-components-react';
import { useTranslation } from "react-i18next";
import { AlertType, OtpRetrievalStatus, WorkflowType } from "src/common/enums";
import { convertDateTimeToLocaleUTCFormat, isEmailValid } from "src/common/util";
import {
    GET_ACTIVE_WORKFLOW_STATE_QUERY, INIT_MFA_REGISTRATION_MUTATION, SIGNAL_WORKFLOW_MUTATION
} from "src/common/gql-operations";
import { useLazyQuery, useMutation } from "@apollo/client";
import {AFTX_3PL_TENANT_ID, NETWORK_ONLY} from "src/common/constants";
import { InitMfaRegistrationData, InitMfaRegistrationVariables } from "src/models/init-mfa-registration";
import { SignalWorkflowVariables } from "src/models/signal-workflow";
import { AlertBar } from "src/components/alert-bar";
import { logger } from "src/logger";
import { GetActiveWorkflowStateData, GetActiveWorkflowStateVariables } from "src/models/get-active-workflow-state";
import { OTP } from "src/models/otp";

import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@amzn/katal-metrics";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('modal', 'register-mfa'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);
const buttonsMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('buttons', additionalMetricsContext);

interface RegisterMfaModalProps {
    open: boolean,
    setOpen: CallableFunction,
    principalArn: string,
    setPrincipalArn: CallableFunction,
    fullName: string,
    setFullName: CallableFunction
}

/**
 * Modal component used for the Register MFA process to generate/retrieve/regenerate the OTP.
 *
 * <ol>
 *     <li>If OTP generation not started, then start OTP generation, wait till it completes, and return OTP.</li>
 *     <li>If OTP generation is already started, then wait till it completes and return OTP.</li>
 *     <li>If OTP generation completed and OTP not expired, then return OTP.</li>
 *     <li>If OTP generation completed and OTP expired, signal workflow to regenerate OTP, wait till it completes, and
 *     return new OTP.</li>
 * </ol>
 *
 * @param registerMfaModalProps the modal properties.
 */
export const RegisterMfaModal = (registerMfaModalProps: RegisterMfaModalProps) => {
    const {t} = useTranslation();
    const history = useHistory();

    const locale = document.documentElement.lang;   // e.g. en-US

    const [otpObj, setOtpObj] = useState<OTP>();
    const [otpRetrievalStatus, setOtpRetrievalStatus] = useState<OtpRetrievalStatus>();
    const [otpRetrievalDetails, setOtpRetrievalDetails] = useState<string>();

    const [email, setEmail] = useState<string>();
    const [emailStatus, setEmailStatus] = useState<string>();
    const [emailDisabled, setEmailDisabled] = useState<boolean>(false);

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    const [onOpenCalled, setOnOpenCalled] = useState<boolean>(false);
    const [onCloseCalled, setOnCloseCalled] = useState<boolean>(false);
    const [callGetActiveWorkflowStateTimeoutId, setCallGetActiveWorkflowStateTimeoutId] = useState<NodeJS.Timeout>();

    // Calls PCS (via FMUI GraphQL back-end) to initialize MFA registration for selected principal
    // - If MFA registration has not been initialized, then new workflow started and workflowId returned
    // - If MFA registration has already been initialized, then workflowId returned for existing workflow
    const [initMfaRegistration, {}] =
        useMutation<InitMfaRegistrationData, InitMfaRegistrationVariables>(INIT_MFA_REGISTRATION_MUTATION, {
            variables: {
                initMfaRegistrationInput: {
                    tenantId: AFTX_3PL_TENANT_ID,
                    principalArn: registerMfaModalProps.principalArn
                }
            },
            onCompleted: data => {
                logger.info("Retrieved data from initMfaRegistration.", data);
                graphqlClientMetricsPublisher.publishCounterMonitor('init-mfa-registration.SUCCESS', 1);
                callGetActiveWorkflowState();
            },
            onError: error => {
                logger.error("Call to initMfaRegistration failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('init-mfa-registration.ERROR', 1);
                showErrorAlert(t('failed-to-init-mfa-registration'), error.message);
                setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
                setOtpRetrievalDetails('');
            }
        });

    // Calls PCS (via FMUI GraphQL back-end) to get workflow state by principalArn and workflowType
    // - If OTP not found inside workflowStateData or OTP is expired, then repeat this request in n seconds
    // - If OTP found, then stop polling and allow user to print/email OTP
    const [getActiveWorkflowState, {}] =
        useLazyQuery<GetActiveWorkflowStateData, GetActiveWorkflowStateVariables>(GET_ACTIVE_WORKFLOW_STATE_QUERY, {
            fetchPolicy: NETWORK_ONLY, // don't cache
            notifyOnNetworkStatusChange: true, // necessary for onCompleted to be called after every poll
            onCompleted: data => {
                logger.info(`Retrieved data for workflowId ${data.getActiveWorkflowState.workflowDetails.workflowId} from getActiveWorkflowState.`);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-active-workflow-state.SUCCESS', 1);
                const workflowDetails = data.getActiveWorkflowState.workflowDetails;
                const workflowId = workflowDetails.workflowId;
                const workflowCurrentStep = workflowDetails.workflowCurrentStep;
                const workflowStateData = workflowDetails.workflowStateData

                const otp = getOtpFromWorkflowStateData(workflowId, workflowStateData);
                processOtp(workflowId, workflowCurrentStep, otp);
            },
            onError: error => {
                logger.error("Call to getActiveWorkflowState failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-active-workflow-state.ERROR', 1);
                showErrorAlert(t('failed-to-get-otp-from-workflow-state-data'), error.message);
                setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
                setOtpRetrievalDetails('');
            }
        });

    // Calls PCS (via FMUI GraphQL back-end) to signal paused workflow to regenerate OTP
    const [signalWorkflowRegenerateOtp, {}] = useMutation<any, SignalWorkflowVariables>(SIGNAL_WORKFLOW_MUTATION, {
        onCompleted: () => {
            logger.info("Signaled workflow to regenerate OTP for principalArn: " + registerMfaModalProps.principalArn);
            graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.regenerate-otp.SUCCESS', 1);
            scheduleCallGetActiveWorkflowState()
        },
        onError: error => {
            logger.error(`Call to signalWorkflow to regenerate OTP ${otpObj?.value} failed!`, error);
            graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.regenerate-otp.ERROR', 1);
            showErrorAlert(t('failed-to-regenerate-otp'), error.message);
            setOtpRetrievalStatus(OtpRetrievalStatus.FAILED);
            setOtpRetrievalDetails('');
        }
    });

    // Calls PCS (via FMUI GraphQL back-end) to signal paused workflow to email OTP
    const [signalWorkflowEmailOtp, {}] = useMutation<any, SignalWorkflowVariables>(SIGNAL_WORKFLOW_MUTATION, {
        onCompleted: () => {
            logger.info("Signaled workflow to email OTP for principalArn: " + registerMfaModalProps.principalArn);
            graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.email-otp.SUCCESS', 1);
            showSuccessAlert(t('email-sent'), `${t('otp-emailed-to')} ${email}`);
            setEmailDisabled(false);
        },
        onError: error => {
            logger.error(`Call to signalWorkflow to email OTP ${otpObj?.value} failed!`, error);
            graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.email-otp.ERROR', 1);
            showErrorAlert(t('failed-to-email-otp'), error.message);
            setEmailDisabled(false);
        }
    });

    useEffect(() => {
        // Check if modal opened
        if (onOpenCalled) {
            callInitMfaRegistration();
        }
    }, [onOpenCalled]);

    useEffect(() => {
        // Check if modal closed
        if (onCloseCalled) {
            // Check if another getActiveWorkflowState request is scheduled
            if (callGetActiveWorkflowStateTimeoutId) {
                logger.info(`Canceled scheduled call to getActiveWorkflowState for `
                    + `timeoutId: ${callGetActiveWorkflowStateTimeoutId} and `
                    + `principalArn: ${registerMfaModalProps.principalArn}`);
                clearTimeout(callGetActiveWorkflowStateTimeoutId);
            }

            resetProps();
        }
    }, [onCloseCalled, callGetActiveWorkflowStateTimeoutId]);

    /**
     * Resets the modal properties (this is necessary since modal is re-used for every principal on the 'Users' page).
     */
    const resetProps = () => {
        setCallGetActiveWorkflowStateTimeoutId(undefined);

        setOtpObj(undefined);
        setOtpRetrievalStatus(undefined);
        setOtpRetrievalDetails(undefined);

        setEmail(undefined);
        setEmailStatus(undefined);
        setEmailDisabled(false);

        resetAlertBar();

        if (registerMfaModalProps.open) {
            // Set to false so modal can be opened again
            // Note: This triggers onClose again which is why onCloseCalled hook is needed to ignore this dup event
            registerMfaModalProps.setOpen(false);
        }
    }

    /**
     * Calls to initialize MFA registration.
     */
    const callInitMfaRegistration = () => {
        logger.info("Calling initMfaRegistration for principalArn: " + registerMfaModalProps.principalArn);
        graphqlClientMetricsPublisher.publishCounterMonitor('init-mfa-registration.INVOCATION', 1);
        initMfaRegistration();
    }

    /**
     * Calls to get the active workflow state by the Principal ARN and Workflow Type.
     */
    const callGetActiveWorkflowState = () => {
        const principalArn = registerMfaModalProps.principalArn;
        logger.info("Calling getActiveWorkflowState for principalArn: " + principalArn);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-active-workflow-state.INVOCATION', 1);
        getActiveWorkflowState({
            variables: {
                getActiveWorkflowStateInput: {
                    tenantId: AFTX_3PL_TENANT_ID,
                    principalArn: principalArn,
                    workflowType: WorkflowType.MFA_REGISTRATION
                }
            },
        });
    }

    /**
     * Calls to signal the workflow to unpause and regenerate the OTP.
     */
    const callSignalWorkflowRegenerateOtp = () => {
        const principalArn = registerMfaModalProps.principalArn;
        logger.info(`Calling signalWorkflow to regenerate OTP for principalArn: ${principalArn}`);
        graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.regenerate-otp.INVOCATION', 1);
        signalWorkflowRegenerateOtp({
            variables: {
                signalWorkflowInput: {
                    tenantId: AFTX_3PL_TENANT_ID,
                    principalArn: principalArn,
                    workflowType: WorkflowType.MFA_REGISTRATION,
                    workflowSignalData: `{\"principalArn\": \"${principalArn}\", \"registrationAction\": \"REGENERATE\"}`
                }
            },
        });
    }

    /**
     * Calls to signal the workflow to unpause and email the OTP.
     */
    const callSignalWorkflowEmailOtp = () => {
        const principalArn = registerMfaModalProps.principalArn;
        logger.info(`Calling signalWorkflow to email OTP for principalArn: ${principalArn}`);
        graphqlClientMetricsPublisher.publishCounterMonitor('signal-workflow.email-otp.INVOCATION', 1);
        signalWorkflowEmailOtp({
            variables: {
                signalWorkflowInput: {
                    tenantId: AFTX_3PL_TENANT_ID,
                    principalArn: principalArn,
                    workflowType: WorkflowType.MFA_REGISTRATION,
                    workflowSignalData: `{\"registrationAction\": \"EMAIL\", \"emailAddress\": \"${email}\"}`
                }
            },
        });
    }

    /**
     * Gets the OTP from the workflow state data (if it exists).
     *
     * @param workflowId the workflow ID.
     * @param workflowStateData the workflow state data which may contain an OTP.
     */
    const getOtpFromWorkflowStateData = (workflowId: string, workflowStateData?: string): OTP | undefined => {
        if (workflowStateData) {
            logger.info(`Found workflowStateData ${workflowStateData} for workflowId ${workflowId}`);
            const otp = JSON.parse(workflowStateData)["otp"];

            // Check if OTP found in workflowStateData
            if (otp) {
                logger.info(`Found OTP ${otp} for workflowId ${workflowId}`);

                const otpCreationDateStr = JSON.parse(workflowStateData)["otpCreationDate"];
                const otpExpirationDateStr = JSON.parse(workflowStateData)["otpExpirationDate"];

                const otpCreationDate = new Date(Number(otpCreationDateStr));
                const otpExpirationDate = new Date(Number(otpExpirationDateStr));

                return {
                    value: otp,
                    creationDate: otpCreationDate,
                    expirationDate: otpExpirationDate,
                    used: otpExpirationDateStr === "-1" // -1 exp. date means that OTP marked as used in FIT STS service
                }
            }
        }
    }

    /**
     * Processes the OTP.
     *
     * @param workflowId the workflow ID.
     * @param workflowCurrentStep the workflow current step.
     * @param otp the One-Time Password.
     */
    const processOtp = (workflowId: string, workflowCurrentStep: string, otp?: OTP) => {
        const principalArn = registerMfaModalProps.principalArn;

        // Check if OTP found
        if (otp) {
            const otpExpDate = convertDateTimeToLocaleUTCFormat(locale, otp.expirationDate);

            // Check if OTP is used
            if (otp.used) {
                logger.info(`Used OTP retrieved for principalArn ${principalArn}.`, otp);
                setOtpRetrievalDetails(t('regenerating-since-otp-used'));

                // Signal workflow to generate new OTP
                // Note: Duplicate requests should be ok after following SIMs are implemented: AFTI-53 and AFTI-168
                callSignalWorkflowRegenerateOtp();
            }
            // Check if OTP is expired
            else if (new Date() > otp.expirationDate) {
                logger.info(`Expired OTP retrieved for principalArn ${principalArn}.`, otp);
                setOtpRetrievalDetails(`${t('regenerating-since-otp-expired-on')} ${otpExpDate}`);

                // Signal workflow to generate new OTP
                // Note: Duplicate requests should be ok after following SIMs are implemented: AFTI-53 and AFTI-168
                callSignalWorkflowRegenerateOtp();
            } else { // Found OTP that is NOT used nor expired
                logger.info(`Valid OTP retrieved for principalArn ${principalArn}.`, otp);
                setOtpObj({
                    value: otp.value,
                    creationDate: otp.creationDate,
                    expirationDate: otp.expirationDate,
                    used: otp.used
                });
                setOtpRetrievalStatus(OtpRetrievalStatus.COMPLETED);
                setOtpRetrievalDetails(`${t('otp-retrieved-that-is-valid-until')} ${otpExpDate}`);
            }
        }
        else { // OTP not found
            logger.info(`OTP not found yet for principalArn ${principalArn}.`, otp);
            setOtpRetrievalDetails(`${t('otp-not-ready-yet-please-wait')}`);

            // Check for OTP again in n seconds
            scheduleCallGetActiveWorkflowState();
        }
    }

    /**
     * Schedule a call to get active workflow state in 5 seconds.
     */
    const scheduleCallGetActiveWorkflowState = () => {
        // Check OTP retrieval status again in 5 seconds
        const timeoutId = setTimeout(() => {
            callGetActiveWorkflowState();
        }, 5000);
        setCallGetActiveWorkflowStateTimeoutId(timeoutId);
        logger.info(`Scheduled getActiveWorkflowState call for timeoutId: ${timeoutId} and ` +
            `principalArn: ${registerMfaModalProps.principalArn}`);
    }

    /**
     * Prints the OTP.
     */
    const printOtp = () => {
        buttonsMetricsPublisher.publishCounterMonitor('print-otp.CLICKS', 1);
        registerMfaModalProps.setOpen(false);

        history.push({
            pathname: '/principal/print-otp',
            state: {
                principalArn: registerMfaModalProps.principalArn,
                fullName: registerMfaModalProps.fullName,
                otp: otpObj!.value,
                otpCreationDate: otpObj!.creationDate,
                otpExpirationDate: otpObj!.expirationDate
            }
        });
    }

    /**
     * Emails the OTP (if the provided email is valid).
     */
    const emailOtp = () => {
        buttonsMetricsPublisher.publishCounterMonitor('email-otp.CLICKS', 1);
        if (!isEmailValid(email)) {
            setEmailStatus(t("email-invalid-status"));
            return;
        }

        setEmailDisabled(true);
        callSignalWorkflowEmailOtp();
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    /**
     * Renders the OTP retrieval status.
     */
    const renderOtpRetrievalStatus = () => {
        if (OtpRetrievalStatus.COMPLETED === otpRetrievalStatus) {
            return (
                <div className="d-inline c-success-alert">
                    <b>{t('completed')}</b>
                    <Icon icon="checkSolid"
                          className="ml-1"
                          id="otp-retrieval-status-icon"
                    />
                </div>
            )
        } else if (OtpRetrievalStatus.FAILED == otpRetrievalStatus) {
            return (
                <div className="d-inline c-error-alert">
                    <b>{t('failed')}</b>
                    <Icon icon="warning"
                          className="ml-1"
                          id="otp-retrieval-status-icon"
                    />
                </div>
            )
        } else {
            return (
                <div className="d-inline-flex align-items-center c-info-alert">
                    <b>{t('in-progress')}</b>
                    <Spinner id="otp-retrieval-status-spinner"
                             // Show spinner if another call to getActiveWorkflowState has been scheduled
                             className={`ml-1 ${callGetActiveWorkflowStateTimeoutId ? 'visible' : 'invisible'}`}
                             size="2"
                             label-position="end"
                    />
                </div>
            )
        }
    }

    /**
     * Renders the email input (which is the email address to which the OTP will be sent).
     */
    const renderEmailInput = () => {
        if (otpRetrievalStatus === OtpRetrievalStatus.COMPLETED) {
            return (
                <div className="row mb-3">
                    <div className="col">
                        <Input id="email-input"
                               label={t('email')}
                               placeholder="jeff@amazon.com"
                               name="email"
                               type="email"
                               value={email}
                               onInput={e => {
                                   setEmail(e.target.value)
                               }}
                               status={isEmailValid(email) ? '' : emailStatus}
                               disabled={emailDisabled}
                        />
                    </div>
                </div>
            )
        }
    }

    /**
     * Renders the email and print buttons (if OTP retrieval has completed successfully).
     */
    const renderEmailAndPrintButtons = () => {
        if (otpRetrievalStatus === OtpRetrievalStatus.COMPLETED) {
            return (
                <div className="row d-flex justify-content-center">
                    <Button id="email-button"
                            size="lg"
                            label={t('email')}
                            onClick={emailOtp}
                            disabled={emailDisabled}
                    />
                    <Button id="print-button"
                            size="lg"
                            label={t('print')}
                            onClick={printOtp}
                    />
                </div>
            )
        }
    }

    return (
        <Modal id="register-mfa-modal"
               className="mb-1"
               header={t('register-mfa')}
               headerDescription={registerMfaModalProps.fullName}
               open={registerMfaModalProps.open}
               onOpen={() => {
                   setOnOpenCalled(true);
                   setOnCloseCalled(false);
               }}
               onClose={() => {
                   setOnOpenCalled(false);
                   setOnCloseCalled(true);
               }}
        >
            <div className="container-fluid">
                <div className="row">
                    <div id="otp-retrieval-status" className="col d-inline">
                        {t('otp-retrieval-status') + " "}
                        {renderOtpRetrievalStatus()}
                    </div>
                </div>
                <div className="row justify-content-center">
                    <p id="otp-retrieval-details">{otpRetrievalDetails}</p>
                </div>
                {renderEmailInput()}
                {renderEmailAndPrintButtons()}
            </div>
            {alertBarMsg &&
                <AlertBar
                    id="register-mfa-modal-alert-bar"
                    result={alertBarType!}
                    dismissible={AlertType.error !== alertBarType}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </Modal>
    )
}