import React from 'react'
import { Autocomplete } from '@amzn/alchemy-components-react'
import { DROPDOWN_MAX_HEIGHT } from '../common/constants'
import { useTranslation } from 'react-i18next'

// https://alchemy.aft.amazon.dev/components/autocomplete
interface AutoCompleteOption {
  /**
   * label for drop down option.
   */
  label: string

  /**
   * value for drop down option.
   */
  value: string
}

/**
 * Input parameters required to create Site Filter component.
 */
interface SiteFilterProps {
  /**
   * Site value to be updated as current site.
   */
  site?: string

  setSite:  React.Dispatch<React.SetStateAction<string | undefined>>

  /**
   * Sites to be displayed in dropdown options.
   */
  sites: AutoCompleteOption[]

  /**
   * To show "Required" status when user didn't selected site
   */
  status: string
}

/**
 * Component to create Site Filter Dropdown.
 */
export const SiteFilter = (siteFilterProps: SiteFilterProps) => {
  const { t } = useTranslation()

  return (
    <Autocomplete
      className="site-filter ml-1"
      id="site-filter"
      label={t('filter-site')}
      label-sr-only
      clickOpen={true}
      search={false}
      options={siteFilterProps.sites}
      value={siteFilterProps.site}
      placeholder={t('filter-site')}
      maxHeight={DROPDOWN_MAX_HEIGHT}
      status={siteFilterProps.status}
      suggestions
      onChange={e => siteFilterProps.setSite(e.target.value)}
    />
  )
}
