import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Accordion, Button, Card } from "@amzn/alchemy-components-react";
import * as Excel from "exceljs";
import { Workbook } from "exceljs";
import { logger } from "src/logger";
import { AlertType, FMUIPageTypes } from "src/common/enums";
import { BreadCrumbs } from 'src/components/breadcrumb';
import { PageProps } from "src/pages/page-interface";
import { AlertBar } from "src/components/alert-bar";
import ValidationResultRow from "src/components/validation-result-row";
import {
    AFTX_3PL_TENANT_ID,
    COLUMN_NAME_CELLS,
    COLUMN_NAME_TRANSLATION_KEYS,
    DATA_SHEET_NAME,
    MIME_TYPE_XLSX
} from "src/common/constants";
import { TemplateTranslator } from "src/pages/onboard/template-translator";
import { useMutation } from "@apollo/client";
import { BULK_ONBOARD_USER_MUTATION } from "src/common/gql-operations";
import { BulkOnboardUserData, BulkOnboardUserVariables } from "src/models/bulk-onboard-user";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import { UserInfo } from "src/models/user-info";
import { ValidationResult } from "src/models/validation-result";
import { FallbackSpinner } from "src/components/fallback-spinner";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'bulk-onboard'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);

export const BulkOnboard = (props: PageProps) => {
    const {t} = useTranslation();

    const inputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>();

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    const [instructionsExpanded, setInstructionsExpanded] = useState<boolean>(true);
    const [validationResults, setValidationResults] = useState<ValidationResult[]>();

    const MAX_ONBOARD_USER_COUNT = 50;
    const COLUMN_NAME_ROW_INDEX = 2;
    const DATA_START_ROW_INDEX = COLUMN_NAME_ROW_INDEX + 1;
    const DATA_END_ROW_INDEX = DATA_START_ROW_INDEX + MAX_ONBOARD_USER_COUNT;

    // Mutation for sending a Bulk Onboard User request to the FMUI GraphQL back-end
    const [bulkOnboardUser, {
        loading
    }] =
        useMutation<BulkOnboardUserData, BulkOnboardUserVariables>(BULK_ONBOARD_USER_MUTATION, {
            onCompleted: data => {
                logger.info("Retrieved data from bulkOnboardUser");

                setValidationResults(data.bulkOnboardUser.validationResults);

                if (!data.bulkOnboardUser.workflowId) {
                    const validationResults = data.bulkOnboardUser.validationResults;
                    validationResults.forEach((validationResult) => {
                        logger.info("Bulk User Onboard data failed validation: " + validationResult.errors);
                    })
                    graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.VALIDATION_ERROR', 1);
                    showErrorAlert(
                        t('submission-failed'),
                        t('please-correct-the-validation-errors-and-resubmit-the-file'));
                } else {
                    logger.info("Bulk User Onboard workflow execution started: " + data.bulkOnboardUser.workflowId);
                    graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.SUCCESS', 1);
                    showSuccessAlert(t('success'), t('bulk-user-onboard-data-submitted'));
                }
            },
            onError: error => {
                logger.error("Call to bulkOnboardUser failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.ERROR', 1);
                showErrorAlert(t('submission-failed'), error.message);
            }
        });

    /**
     * Sets the active page in the navbar (triggered on page load).
     */
    useEffect(() => {
        props.setActivePage(FMUIPageTypes.ONBOARD);
    }, []);

    /**
     * Calls to bulk onboard users.
     */
    const callBulkOnboardUser = (users: UserInfo[]) => {
        setValidationResults([]); // reset results

        logger.info(`Calling bulkOnboardUser for ${users.length} users`);
        graphqlClientMetricsPublisher.publishCounterMonitor('bulk-onboard-user.INVOCATION', 1);
        bulkOnboardUser({
            variables: {
                bulkOnboardUserInput: {
                    tenantId: AFTX_3PL_TENANT_ID,
                    users: users
                }
            },
        });
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows a warning via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showWarningAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.warning);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    /**
     * Opens the file selection dialog window.
     */
    const openFileSelectionDialogWindow = () => {
        // Click on hidden input to trigger file section dialog window to open
        // NOTE: Using hidden input element to be able to customize look-and-feel of file upload input element since
        // Alchemy doesn't provide out-of-the-box component and HTML default file input is not consistent with styling
        // of rest of Alchemy custom components
        inputRef.current!.click();
    }

    /**
     * Stores the file selection.
     * @param e the event.
     */
    const storeFileSelectionChoice = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    }

    /**
     * Handles the event when the 'Submit' button is clicked.
     */
    const handleSubmit = async () => {
        if (!file) {
            showWarningAlert(t('no-file-selected'), t('please-select-a-file-and-try-again'));
            return;
        }

        try {
            const users = await processUploadedFile(file);
            setInstructionsExpanded(false);
            callBulkOnboardUser(users);
        } catch (e) {
            logger.error('Failed to submit bulk user onboard data!', e as Error);
        }
    }

    /**
     * Processes the uploaded file by validating it and parsing the bulk user onboard data from it.
     */
    const processUploadedFile = async (file: File) => {
        const data = await file.arrayBuffer();
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(data);

        validateSheets(workbook);

        const rows = parseData(workbook);

        validateRows(rows);
        validateColumns(rows[COLUMN_NAME_ROW_INDEX]);

        return transformData(rows.slice(DATA_START_ROW_INDEX));
    }

    /**
     * Validates that the required sheet exists in the workbook.
     *
     * @param workbook the workbook.
     */
    const validateSheets = (workbook: Workbook) => {
        const sheetNames: string[] = workbook.worksheets.map(sheet => sheet.name);

        if (!sheetNames.includes(DATA_SHEET_NAME)) {
            showErrorAlert(
                t('submission-failed'),
                t('workbook-is-missing-sheet', {
                    sheetName: DATA_SHEET_NAME
                })
            );
            throw new Error(`Workbook is missing sheet: ${DATA_SHEET_NAME}`);
        }
    }

    /**
     * Validates that the required columns exist in the worksheet.
     *
     * @param row the row to validate.
     */
    const validateColumns = (row: string[]) => {
        for (let i = 0; i < COLUMN_NAME_TRANSLATION_KEYS.length; i++) {
            const expectedColumnName = t(COLUMN_NAME_TRANSLATION_KEYS[i]);

            // Check if column is missing or misordered
            if (!row.includes(expectedColumnName) || row[i] !== expectedColumnName) {
                showErrorAlert(
                    t('submission-failed'),
                    t('sheet-missing-or-misordered-columns', {
                        sheetName: DATA_SHEET_NAME,
                    })
                );
                throw new Error('Sheet has missing or misordered columns!');
            }
        }
    }

    /**
     * Validates that at least 1 row with onboarding data exists in the worksheet and that the max number of rows
     * allowed isn't exceeded.
     *
     * @param rows the rows to validate.
     */
    const validateRows = (rows: string[][]) => {
        if (rows.length <= DATA_START_ROW_INDEX) {
            showErrorAlert(
                t('submission-failed'),
                t('sheet-missing-rows', {
                    sheetName: DATA_SHEET_NAME
                })
            );
            throw new Error('Sheet is missing rows!');
        } else if (rows.length > DATA_END_ROW_INDEX) {
            showErrorAlert(
                t('submission-failed'),
                t('sheet-too-many-rows', {
                    sheetName: DATA_SHEET_NAME,
                    maxOnboardUserCount: MAX_ONBOARD_USER_COUNT
                })
            );
            throw new Error('Sheet has too many rows!');
        }
    }

    /**
     * Parses the bulk user onboard data from the workbook.
     *
     * @param workbook the workbook to parse.
     */
    const parseData = (workbook: Workbook): string[][] => {
        const worksheet = workbook.getWorksheet(DATA_SHEET_NAME);
        let rows: string[][] = [];

        // Iterate over each non-empty row (up to max row index + 1) and store cell data (up to last column index)
        // Note: Allow 'rows' to store 1 over max in order to perform validation in validateRows()
        worksheet.eachRow({includeEmpty: false}, (row, rowNumber) => {
            if (rowNumber <= (DATA_END_ROW_INDEX + 1)) {
                let cells: string[] = [];
                for (let i = 1; i <= COLUMN_NAME_CELLS.length; i++) {
                    cells.push(getCellValue(row.getCell(i)));
                }
                rows.push(cells);
            }
        });

        return rows;
    }

    /**
     * Gets the cell's value as a string.
     *
     * @param cell the cell from which to get the value.
     */
    const getCellValue = (cell: Excel.Cell): string => {
        // Use cell.value to check if null since cell.text throws an exception if value is null
        const value = cell.value;
        if (!value) {
            // convert null to empty string
            return "";
        } else if (typeof value !== 'string') {
            // get text value of cell
            return cell.text;
        } else { // value is 'string' type
            return value;
        }
    }

    /**
     * Transforms the row data from string[][] to UserInfo[].
     *
     * @param rows the rows to transform.
     */
    const transformData = (rows: string[][]): UserInfo[] => {
        let users: UserInfo[] = [];

        rows.forEach((cells) => {
            let i = 0;

            users.push({
                scope: cells[i++],
                userType: cells[i++],
                startDate: cells[i++],
                endDate: cells[i++],
                legalName: {
                    firstName: cells[i++],
                    middleName: cells[i++],
                    lastName: cells[i++]
                },
                preferredName: {
                    firstName: cells[i++],
                    middleName: cells[i++],
                    lastName: cells[i++]
                },
                email: cells[i++],
                gender: cells[i++],
                dateOfBirth: cells[i++],
                nationalIdCountry: cells[i++],
                nationalIdType: cells[i++],
                nationalId: cells[i++],
                backgroundCheckVendorName: cells[i++],
                backgroundCheckReferenceNumber: cells[i++],
                backgroundCheckExceptionId: cells[i++]
            });
        });

        return users;
    }

    /**
     * Handles the event that occurs when a user clicks on the link to download the template.
     */
    const handleTemplateLinkClick = async () => {
        const templateFileName = 'fmui-bulk-onboard-template.xlsx';
        const templateFilePath = `/assets/${templateFileName}`;

        // Fetch template
        const response = await fetch(templateFilePath);
        const bufferIn = await response.arrayBuffer();

        // Convert template array buffer into Excel workbook
        const workbook = new Excel.Workbook();
        await workbook.xlsx.load(bufferIn);

        // Translate template into user's selected language
        const templateTranslator = new TemplateTranslator(t);
        templateTranslator.translateWorkbook(workbook);

        // Write template out to a Blob
        const bufferOut = await workbook.xlsx.writeBuffer();
        const data = new Blob([bufferOut], {type: MIME_TYPE_XLSX});

        // Set up hidden link to download translated template
        const link = document.getElementById('template-download-hidden-link') as HTMLAnchorElement;
        link.href = URL.createObjectURL(data);
        link.download = `fmui-bulk-onboard-template_${document.documentElement.lang}.xlsx`;

        // Click hidden link (which downloads template to user's Downloads dir)
        link.click();
    }

    return (
        <div id="bulk-onboard-page" className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[
                    {tag: t('onboard'), path: '/onboard'},
                    {tag: t('bulk'), path: '/onboard/bulk'}
                ]}/>
            </div>
            <div className="row mx-0 mb-0-5 b-background">
                <div className="col title m-0 py-1">{t('bulk-user-onboard')}</div>
            </div>
            <div className="b-background">
                <div className="row mx-0 mb-0-5 px-2 pt-2 pb-4">
                    <div className="col">
                        <Accordion id="instructions-accordion" header="Instructions" expanded={instructionsExpanded}>
                            <ol>
                                <li>
                                    {t('click')}
                                    <Link id="template-download-link"
                                          to="#"
                                          onClick={handleTemplateLinkClick}>
                                        &nbsp;{t('here')}&nbsp;
                                    </Link>
                                    <a id="template-download-hidden-link" target="_blank" hidden></a>
                                    {t('bulk-onboard-instructions-step-1')}
                                </li>
                                <li>
                                    {t('bulk-onboard-instructions-step-2', {
                                        maxOnboardUserCount: MAX_ONBOARD_USER_COUNT
                                    })}
                                </li>
                                <li>{t('bulk-onboard-instructions-step-3')}</li>
                                <li>
                                    {t('click-on-the')}
                                    <b> {t('select-file')} </b>
                                    {t('bulk-onboard-instructions-step-4')}
                                </li>
                                <li>
                                    {t('click-on-the')}
                                    <b> {t('submit')} </b>
                                    {t('bulk-onboard-instructions-step-5')}
                                </li>
                                <li>{t('bulk-onboard-instructions-step-6')}</li>
                                <li>{t('bulk-onboard-instructions-step-7')}</li>
                            </ol>
                        </Accordion>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col">
                        <Card id="file-upload-card" className='mx-auto px-4'>
                            <div className="row pb-2">
                                <div className="col-auto pr-0">
                                    <Button
                                        id="select-file-button"
                                        label={t('select-file')}
                                        onClick={openFileSelectionDialogWindow}
                                    />
                                    <input ref={inputRef}
                                           id="input-select-file"
                                           type="file"
                                           onChange={storeFileSelectionChoice}
                                           accept=".xlsx"
                                           hidden/>
                                </div>
                                <div className="col">
                                    <p id="selected-file-p">{file ? file.name : t('no-file-selected')}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Button
                                        id="submit-button"
                                        className="c-success-alert"
                                        fullWidth={true}
                                        label={t('submit')}
                                        variant="action"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {(validationResults && validationResults.length > 0) &&
                    <div className="row px-2 pb-2">
                        <div className="col">
                            <div className="table-responsive">
                                <table id="results-table" className="alchemy-table bordered">
                                    <thead>
                                    <tr>
                                        <th>{t('bulk-onboard-results-table-col-header-excel-row-number')}</th>
                                        <th>{t('bulk-onboard-results-table-col-header-validation-errors')}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {validationResults.map((item, i) => {
                                        if (item.errors.length > 0) {
                                            return (
                                                <ValidationResultRow
                                                    key={i}
                                                    excelRowNum={DATA_START_ROW_INDEX + 1 + i}
                                                    validationErrors={item.errors}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {loading &&
                <FallbackSpinner/>
            }
            {alertBarMsg &&
                <AlertBar
                    id="bulk-onboard-alert-bar"
                    result={alertBarType!}
                    dismissible={true}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </div>
    );
}

export default BulkOnboard;