import {useTranslation} from "react-i18next";
import {TFunction} from "i18next";
import React, {useEffect, useState} from "react";
import {useLocation} from 'react-router-dom';
import {
    AlertType,
    FMUIPageTypes,
} from "src/common/enums";
import {BreadCrumbs} from "src/components/breadcrumb";
import {Button, Input} from "@amzn/alchemy-components-react";
import {PageProps} from "src/pages/page-interface";
import {AlertBar, AlertBarProps} from "src/components/alert-bar";
import {
    FIT_ENDPOINT,
    MILO_REGISTRATION_ENDPOINT,
    MILO_REGISTRATION_ERROR_STATUS,
    MILO_REGISTRATION_FIT_CLIENT_ID,
    MILO_REGISTRATION_FIT_TENANT,
    MILO_REGISTRATION_SUCCESS_STATUS,
    OTP_LENGTH,
    PIS_ERROR_OTP_EXPIRED,
    PIS_ERROR_OTP_EXPIRED_TRANSLATION_KEY,
    PIS_ERROR_OTP_NOT_FOUND,
    PIS_ERROR_OTP_NOT_FOUND_TRANSLATION_KEY,
    PIS_ERROR_OTP_USED,
    PIS_ERROR_OTP_USED_TRANSLATION_KEY,
    PROXY_IDP_SUCCESS_STATUS,
} from "src/common/constants";
import {logger} from "src/logger";
import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@amzn/katal-metrics";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'mfa-registration'),
]
// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const otpRedirectMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('otp-redirect', additionalMetricsContext);
const miloRedirectMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('milo-redirect', additionalMetricsContext);

/**
 * @description MFA Registration Redirection Logic
 *  Source: {@link https://plantuml.corp.amazon.com/plantuml/form/encoded.html#encoded=ZLJBRjim4BppAnQ-soq2z694ILhO1WHKYM4xkGH5mOfhXp15gdpOIOh-UvUKP5XENEGYGAlTcU7iK3VES-j3fHWFtkXG_KBBt5RgcbjUGSMV2tIERt0fNn4kldncZ9VUMBXtrCYyz0gXc7s51MwayvPxQJJCbDapbcNmm9KKt2Fu9uIxrHou5b1QZ2KEitm5IuAF8tlfdrBNV550BY3BMAI0uHNCYljy3CkIQmVVk2sDG205fPBbri6qubA1adeBte138Y2Yg5Bk8asSPl6HeFBLcAGAQR7qiJzANw7csZSAeaRRVlyPhCp-hAiW_rwJJJwup8Mo9C73sfhd5obg5ez0kFDysSVTTGVlQybNZ5fXs8qyzDgKRmhf3I1rjfeFzOEwo1BzEbPxREzQUd8ZcKjOhSx6oiL_81WgXv3htSU59EEkqLfZiz5eTChsWGc1FOi0kdt528nX8bsj-0iC5lWxeFDmY_i4M3NP7C44FQN0ZU5o7ZUJYpaazAi10AD8i1v5J4HCPvDP2baq8IPXWZjP8WGdzIOL6oz5BwcKp4vUaN-_EyaJLHAkqNJiLngTQb4RIVdw55HbZsx9IKJJvENXp0TjZQOKZbPUxwejcqIkWoBR4iR1-DFGLbAPizwsED1UTtYStav-D65uZtP6TgCuIxccpy7Y-vI3ZlFCEWSTEUtjpTfkg1f_L_y0}
 *
 * @param otp 16 Digit One Time Password String
 * @param status Status QueryString Parameter received
 * @param error error Query String Parameter received
 * @param setMessage  Function to set the message in the alertBar
 * @param t Translate Function
 * @returns void
 */
export const mfaRedirect = (
    otp: string | null,
    status: string | null,
    error: string | null,
    setMessage: CallableFunction,
    t: TFunction
) => {
    if (otp) {
        otpValidateRedirect(otp);
        return;
    }

    if (error) {
        logger.info("Invalid OTP");
        otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.ERROR', 1)

        let message;
        switch (error) {
            case PIS_ERROR_OTP_USED:
                message = t(PIS_ERROR_OTP_USED_TRANSLATION_KEY);
                otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.ERROR.OTP_USED', 1)
                break;
            case PIS_ERROR_OTP_EXPIRED:
                message = t(PIS_ERROR_OTP_EXPIRED_TRANSLATION_KEY);
                otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.ERROR.OTP_EXPIRED', 1)
                break;
            case PIS_ERROR_OTP_NOT_FOUND:
                message = t(PIS_ERROR_OTP_NOT_FOUND_TRANSLATION_KEY);
                otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.ERROR.OTP_NOT_FOUND', 1)
                break;
            default:
                message = `${t('proxy-idp-error-generic')} ${error}`
                otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.ERROR.GENERIC', 1)
                break;
        }
        setMessage({
            result: AlertType.error,
            header: t('mfa-registration-error-header'),
            message: message
        });
        return;
    }

    switch (status) {
        case PROXY_IDP_SUCCESS_STATUS:
            logger.info("MFA Registration Redirect - Valid OTP");
            otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect.SUCCESS', 1)
            registerMfaRedirect();
            break;
        case MILO_REGISTRATION_ERROR_STATUS:
            logger.info("MFA Device Registration Failed");
            miloRedirectMetricPublisher.publishCounterMonitor('milo-registration-redirect.ERROR', 1)
            setMessage({
                result: AlertType.error,
                header: t('mfa-registration-error-header'),
                message: t('mfa-registration-error-message')
            });
            break;
        case MILO_REGISTRATION_SUCCESS_STATUS:
            logger.info("MFA Device Registration Success");
            miloRedirectMetricPublisher.publishCounterMonitor('milo-registration-redirect.SUCCESS', 1)
            setMessage({
                result: AlertType.success,
                header: t('mfa-registration-success-header'),
                message: t('mfa-registration-success-message')
            });
            break;
    }
}


export const otpValidateRedirect = (otp: string) => {
    if (otp.length != OTP_LENGTH) {
        logger.info(`OTP length ${otp.length} is not required ${OTP_LENGTH}`);
        return
    }
    if (!otp.match(/^[0-9]*$/)) {
        logger.info(`OTP ${otp} is not numeric`);
        return
    }

    logger.info(`Redirecting to FIT with OTP: ${otp}`);
    otpRedirectMetricPublisher.publishCounterMonitor('otp-redirect', 1);

    const redirect_uri = `https://${window.location.host}/mfa-registration/?status=${PROXY_IDP_SUCCESS_STATUS}`;  // does not include query string
    const referrer = `https://${window.location.host}/mfa-registration/`;

    // replace is used, so the back button works as expected
    window.location.replace(`${FIT_ENDPOINT}/${MILO_REGISTRATION_FIT_TENANT}/authorize?` +
        `client_id=${MILO_REGISTRATION_FIT_CLIENT_ID}` +
        `&redirect_uri=${redirect_uri}` +
        `&response_type=code` +
        `&scope=openid tenantId:${MILO_REGISTRATION_FIT_TENANT}` +
        `&referrer=${referrer}` +
        `&otp=${otp}`);
}

export const registerMfaRedirect = () => {
    logger.info("Redirecting to MILO Registration Endpoint")
    miloRedirectMetricPublisher.publishCounterMonitor('milo-redirect', 1);

    // replace is used, so the back button works as expected
    window.location.replace(MILO_REGISTRATION_ENDPOINT);
}

/**
 * Renders Associate MFA Registration Page.
 * @param props {PageProps}
 */
export const MfaRegistration = (props: PageProps) => {
    const {t} = useTranslation();
    const searchParams = new URLSearchParams(useLocation().search);
    const [otp, setOtp] = useState<string>(searchParams.get('otp') || '');
    const [otpStatus, setOtpStatus] = useState<string>('');
    const [statusParam] = useState<string>(searchParams.get('status') || '');
    const [errorParam] = useState<string>(searchParams.get('error') || '');
    const [alertBarProps, setAlertBarProps] = useState<AlertBarProps|null>(null);

    useEffect(() => {
        logger.info(`Query string parameters sent in url request : ${searchParams.toString()}`);

        // Leave OTP param null here to avoid automatic redirection to FIT /authorize on page load since some email
        // providers (such as Outlook) auto-invoke URLs in emails, so wait till user explicitly clicks Submit button
        mfaRedirect(null, statusParam, errorParam, setAlertBarProps, t);  // Update status/error based on searchParams

        props.setActivePage(FMUIPageTypes.MFA_REGISTRATION);
        document.getElementById('otp-input')?.focus();
    }, []);

    /**
     * Validates OTP.
     *
     * @param value {string} otp
     * @param submit {boolean} intent is to submit
     * @returns {boolean} of the valid status
     */
    const validOtp = (value: string, submit: boolean): boolean => {
        logger.info(`Validating OTP: "${value}"`);

        let valid = true;
        let translateString = "otp";
        if (submit && value.length !== OTP_LENGTH) {
            // Only validate the length when submitting
            logger.info(`OTP '${value}' is not required ${OTP_LENGTH} characters. It is ${value.length}.`);
            valid = false;
            translateString += "-length";
        }
        if (!value.match(/^[0-9]*$/)) {
            logger.info(`OTP ${value} is not only numeric.`);
            valid = false;
            translateString += "-numeric";
        }

        if (!valid) {
            setOtpStatus(t(translateString + "-invalid"));

        } else {
            setOtpStatus('');
        }
        return valid;
    }

    const handleBarCodeInput = (e: any) => {
        const value = e.target.value;
        setOtp(value);
    }

    const handlePressEnter = (e: any) => {
        if(e.key === 'Enter') {
            handleSubmit();
        }
    }

    const handleSubmit = () => {
        logger.info("Attempting to submit the OTP for MFA Registration.")
        if (validOtp(otp, true)) {
            mfaRedirect(otp, null, null, setAlertBarProps, t);
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <BreadCrumbs breadcrumbItems={[{tag: t('register-mfa')}]}/>
            </div>

            <div className="row b-background mx-0 mb-0-5">
                <div className="col align-self-center title m-0 py-1">{t('mfa-registration-header')}</div>
            </div>

            <div className="row d-flex py-2 mx-0 justify-content-center b-background">
                <div className="col-12 align-self-center m-0 py-1">
                    <Input
                        id="otp-input"
                        maxlength={16}
                        label={t('otp-label')}
                        helpText={t('otp-help-text')}
                        placeholder={t('scan-barcode')}
                        value={otp}
                        onInput={handleBarCodeInput}
                        status={otpStatus}
                        onKeyDown={handlePressEnter}
                    />
                </div>
            </div>

            <div className="mt-0 py-2 mx-0 mb-5 row d-flex justify-content-center b-background">
                <Button
                    id="otp-button"
                    size="lg"
                    label={t('submit')}
                    onClick={handleSubmit}
                />
            </div>

            {alertBarProps != null &&
                <AlertBar
                    result={alertBarProps.result}
                    header={alertBarProps.header}
                    message={alertBarProps.message}
                />
            }
        </div>
    )
};