import { gql } from "@apollo/client";

// GraphQL Queries

export const LIST_PRINCIPALS_QUERY = gql`
  query listPrincipals($listPrincipalsInput: ListPrincipalsInput!) {
    listPrincipals(listPrincipalsInput: $listPrincipalsInput) {
      tenantId
      principals {
        principalArn
        userType
        scope
        lastBaseliningDate
        lastBaselinedByPrincipal
        firstName
        lastName
      }
    }
  }
`;

export const LIST_WORKFLOWS_QUERY = gql`
  query listWorkflows($listWorkflowsInput: ListWorkflowsInput!) {
    listWorkflows(listWorkflowsInput: $listWorkflowsInput) {
      workflowDetails {
        creationDate
        completionDate
        workflowId
        workflowType
        workflowState
        workflowCurrentStep
        parentWorkflowId
        createdByPrincipal
        workflowVersion
        workflowSignalWaitIndex
        workflowSignalWakeIndex
        workflowStateData
        principalWorkflowArn
        workflowTTL
      }
    }
  }
`;

export const LIST_SCOPES_QUERY = gql`
  query listScopes($listScopesInput: ListScopesInput!) {
    listScopes(listScopesInput: $listScopesInput) {
      tenantId
      scopes
    }
  }
`;

export const GET_PRINCIPAL_SCOPES_QUERY = gql`
  query getPrincipalScopes($getPrincipalScopesInput: GetPrincipalScopesInput!) {
    getPrincipalScopes(getPrincipalScopesInput: $getPrincipalScopesInput) {
      scopes
    }
  }
`;

export const GET_WORKFLOW_STATE_QUERY = gql`
  query getWorkflowState($getWorkflowStateInput: GetWorkflowStateInput!) {
    getWorkflowState(getWorkflowStateInput: $getWorkflowStateInput) {
      workflowDetails {
        creationDate
        completionDate
        workflowId
        workflowType
        workflowState
        workflowCurrentStep
        parentWorkflowId
        createdByPrincipal
        workflowVersion
        workflowSignalWaitIndex
        workflowSignalWakeIndex
        workflowStateData
        principalWorkflowArn
        workflowTTL
      }
    }
  }
`;

export const GET_ACTIVE_WORKFLOW_STATE_QUERY = gql`
  query getActiveWorkflowState($getActiveWorkflowStateInput: GetActiveWorkflowStateInput!) {
    getActiveWorkflowState(getActiveWorkflowStateInput: $getActiveWorkflowStateInput) {
      workflowDetails {
        creationDate
        completionDate
        workflowId
        workflowType
        workflowState
        workflowCurrentStep
        parentWorkflowId
        createdByPrincipal
        workflowVersion
        workflowSignalWaitIndex
        workflowSignalWakeIndex
        workflowStateData
        principalWorkflowArn
        workflowTTL
      }
    }
  }
`;

// GraphQL Mutations

export const ONBOARD_USER_MUTATION = gql`
  mutation OnboardUser($onboardUserInput: OnboardUserInput!) {
    onboardUser(onboardUserInput: $onboardUserInput) {
      workflowId
      validationResult {
        errors {
          translationKey
          placeholderValues
        }
      }
    }
  }
`;

export const BULK_ONBOARD_USER_MUTATION = gql`
  mutation bulkOnboardUser($bulkOnboardUserInput: BulkOnboardUserInput!) {
    bulkOnboardUser(bulkOnboardUserInput: $bulkOnboardUserInput) {
      workflowId
      validationResults {
        user {
          scope
          legalName {
            firstName
            middleName
            lastName
            prefix
            suffix
          }
          preferredName {
            firstName
            middleName
            lastName
            prefix
            suffix
          }
          userType
          gender
          email
          dateOfBirth
          startDate
          endDate
          nationalIdType
          nationalIdCountry
          nationalId
          backgroundCheckVendorName
          backgroundCheckReferenceNumber
          backgroundCheckExceptionId
        }
        errors {
          translationKey
          placeholderValues
        }
      }
    }
  }
`;

export const INIT_MFA_REGISTRATION_MUTATION = gql`
  mutation initMfaRegistration($initMfaRegistrationInput: InitMfaRegistrationInput!) {
    initMfaRegistration(initMfaRegistrationInput: $initMfaRegistrationInput) {
      workflowId
    }
  }
`;

export const SIGNAL_WORKFLOW_MUTATION = gql`
  mutation signalWorkflow($signalWorkflowInput: SignalWorkflowInput!) {
    signalWorkflow(signalWorkflowInput: $signalWorkflowInput)
  }
`;

export const REMOVE_SCOPE_MUTATION = gql`
  mutation RemoveScope($removeScopeInput: RemoveScopeInput!) {
    removeScope(removeScopeInput: $removeScopeInput) {
      workflowId
    }
  }
`;