// Supported languages for the 'operations' I18N family (which includes AFT)
// https://w.amazon.com/bin/view/INTech/Panther/I18nFamily/#Hoperations
//
// A full list in Excel format can be found here:
// https://w.amazon.com/bin/view/AFT/UX/Localization/getting_started/#HAFTandOperationsTargetLanguages
//
// 'label' is the language name
// 'value' is the language-country pair (locale)
//
// The comment after each label is the native language name
//
// The languages below match up 1:1 with the PUFF JSON files in the /translations folder
// The commented out languages below are supported by the operation i18n family but not by the Alchemy Datepicker
export const Languages = [
    {
        label: 'Afrikaans', // Afrikaans
        value: 'af-ZA'
    },
    {
        label: 'العربية (الإمارات العربية المتحدة)', // Arabic (UAE)
        value: 'ar-AE'
    },
    {
        label: 'العربية (السعودية - المملكة العربية السعودية)', // Arabic (Saudi-Arabia)
        value: 'ar-SA',
    },
    {
        label: 'български', // Bulgarian (Bulgaria)
        value: 'bg-BG'
    },
    // {
    //     label: 'বাংলা', // Bengali
    //     value: 'bn-IN',
    // },
    {
        label: 'Čeština', // Czech
        value: 'cs-CZ',
    },
    {
        label: 'Deutsch', // German
        value: 'de-DE',
    },
    {
        label: 'English (UAE)', // English (UAE)
        value: 'en-AE',
    },
    {
        label: 'English (Australia)', // English (Australia)
        value: 'en-AU',
    },
    {
        label: 'English (Canada)', // English (Canada)
        value: 'en-CA',
    },
    {
        label: 'English (Great Britain)', // English (Great Britain)
        value: 'en-GB',
    },
    {
        label: 'English (India)', // English (India)
        value: 'en-IN'
    },
    {
        label: 'English (Singapore)', // English (Singapore)
        value: 'en-SG',
    },
    {
        label: 'English (US)', // English (US)
        value: 'en-US',
    },
    {
        label: 'Español (Argentina)', // Spanish (Argentina)
        value: 'es-AR'
    },
    {
        label: 'Español (Chile)', // Spanish (Chile)
        value: 'es-CL'
    },
    {
        label: 'Español (Colombia)', // Spanish (Colombia)
        value: 'es-CO'
    },
    {
        label: 'Español (España)', // Spanish (Spain)
        value: 'es-ES',
    },
    {
        label: 'Español (México)', // Spanish (Mexico)
        value: 'es-MX',
    },
    {
        label: 'Español (Estados Unidos)', // Spanish (US)
        value: 'es-US'
    },
    {
        label: 'دری', // Dari
        value: 'fa-AF'
    },
    {
        label: 'Français (Canada)', // French (Canada)
        value: 'fr-CA',
    },
    {
        label: 'Français (France)', // French (France)
        value: 'fr-FR',
    },
    // {
    //     label: 'ગુજરાતી', // Gujarati
    //     value: 'gu-IN'
    // },
    // {
    //     label: 'ََرْشَن هَوْسَ', // Hausa
    //     value: 'ha-NG'
    // },
    {
        label: 'ִבְרִית', // Hebrew
        value: 'he-IL'
    },
    // {
    //     label: 'हिन्दी', // Hindi
    //     value: 'hi-IN',
    // },
    // {
    //     label: 'kreyòl ayisyen', // Haitian (Creole)
    //     value: 'ht-HT',
    // },
    {
        label: 'magyar', // Hungarian
        value: 'hu-HU',
    },
    {
        label: 'Indonesia', // Indonesian
        value: 'id-ID'
    },
    // {
    //     label: 'Igbo (Nigeria)', // Igbo (Nigeria)
    //     value: 'ig-NG',
    // },
    {
        label: 'Italiano', // Italian
        value: 'it-IT',
    },
    {
        label: '日本語', // Japanese
        value: 'ja-JP',
    },
    // {
    //     label: 'ಕನ್ನಡ', // Kannada
    //     value: 'kn-IN'
    // },
    {
        label: '한국어', // Korean
        value: 'ko-KR'
    },
    {
        label: 'Lietuvių', // Lithuanian
        value: 'lt-LT',
    },
    {
        label: 'latviešu valoda', // Latvian
        value: 'lv-LV'
    },
    // {
    //     label: 'മലയാളം', // Malayalam
    //     value: 'ml-IN'
    // },
    // {
    //     label: 'मराठी', // Marathi
    //     value: 'mr-IN'
    // },
    // {
    //     label: 'မြန်မာစာ', // Burmese
    //     value: 'my-MM',
    // },
    // {
    //     label: 'नेपाली', // Nepali
    //     value: 'ne-NP',
    // },
    {
        label: 'nederlands', // Dutch
        value: 'nl-NL'
    },
    // {
    //     label: 'ਪੰਜਾਬੀ', // Punjabi
    //     value: 'pa-IN'
    // },
    {
        label: 'Polski', // Polish
        value: 'pl-PL',
    },
    // {
    //     label: 'پښتو', // Pashto
    //     value: 'ps-AF'
    // },
    // {
    //     label: 'Português (Brasil)', // Portuguese (Brazil)
    //     value: 'pt-BR',
    // },
    // {
    //     label: 'Português (Portugal)', // Portuguese (Portugal)
    //     value: 'pt-PT'
    // },
    {
        label: 'Română', // Romanian
        value: 'ro-RO',
    },
    {
        label: 'Русский', // Russian
        value: 'ru-RU',
    },
    {
        label: 'Slovenčina', // Slovak
        value: 'sk-SK',
    },
    // {
    //     label: 'slovenščina', // Slovenian
    //     value: 'sl-SI'
    // },
    // {
    //     label: 'Somali', // Somali
    //     value: 'so-SO',
    // },
    {
        label: 'svenska', // Swedish (Sweden)
        value: 'sv-SE'
    },
    // {
    //     label: 'தமிழ்', // Tamil (India)
    //     value: 'ta-IN'
    // },
    // {
    //     label: 'తెలుగు', // Telugu (India)
    //     value: 'te-IN'
    // },
    // {
    //     label: 'Tagalog', // Tagalog (Philippines)
    //     value: 'tl-PH',
    // },
    {
        label: 'Türkçe', // Turkish
        value: 'tr-TR',
    },
    {
        label: 'Українська', // Ukrainian
        value: 'uk-UA',
    },
    {
        label: 'ُردُو', // Urdu
        value: 'ur-PK',
    },
    {
        label: 'tiếng việt', // Vietnamese
        value: 'vi-VN',
    },
    // {
    //     label: 'isiXhosa', // Xhosa
    //     value: 'xh-ZA'
    // },
    // {
    //     label: 'Èdè Yorùbá', // Yoruba
    //     value: 'yo-NG'
    // },
    {
        label: '汉语', // Chinese (Simplified/PRC)
        value: 'zh-CN',
    },
    {
        label: '漢語', // Chinese (Traditional (Taiwan/Hong Kong))
        value: 'zh-TW'
    },
    // {
    //     label: 'isiZulu', // Zulu
    //     value: 'zu-ZA'
    // }
]