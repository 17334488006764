import { Workbook, Worksheet } from "exceljs";
import { GENDER_TYPES, USER_TYPES, UserTypes } from "src/common/enums";
import {
    COLUMN_NAME_CELLS, COLUMN_NAME_TRANSLATION_KEYS, DATA_SHEET_NAME, PRM_DATE_FORMAT
} from "src/common/constants";
// @ts-ignore
import { TFunction } from "react-i18next";

/**
 * Class used to translate the Bulk User Onboard template Excel file.
 */
export class TemplateTranslator {
    private readonly t: TFunction;

    constructor(t: TFunction) {
        this.t = t;
    }

    /**
     * Translates the workbook.
     *
     * @param workbook The workbook to translate.
     */
    translateWorkbook(workbook: Workbook) {
        this.#translateInstructionsWorksheet(workbook);
        this.#translateExampleWorksheet(workbook);
        this.#translateDataWorksheet(workbook);
    }

    /**
     * Translates the 'Instructions' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #translateInstructionsWorksheet(workbook: Workbook) {
        const worksheet = workbook.worksheets[0];

        this.#translateName(worksheet, this.t('instructions'));
        this.#translateTitle(worksheet);

        worksheet.getCell('A2').value = this.t('bulk-onboard-template-instructions-step-1', {
            sheetName: this.t('example')
        }) as string;

        worksheet.getCell('A3').value = this.t('bulk-onboard-template-instructions-step-2', {
            sheetName: DATA_SHEET_NAME
        }) as string;

        worksheet.getCell('A5').value = this.t('bulk-onboard-template-instructions-step-3', {
            sheetName: DATA_SHEET_NAME,
            pageName: this.t('bulk-user-onboard')
        }) as string;
    }

    /**
     * Translates the 'Example' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #translateExampleWorksheet(workbook: Workbook) {
        const worksheet = workbook.worksheets[1];

        this.#translateName(worksheet, this.t('example'));
        this.#translateTitle(worksheet);
        this.#translateSectionHeaders(worksheet);
        this.#translateColumnNames(worksheet);

        // Data requirements separator row

        worksheet.getCell('A8').value = this.t('buot-data-requirements-for-each-field-are-listed-below') as string;

        // Required, Optional, or Required/Optional row

        worksheet.getCell('A9').value = this.t('required') as string;
        worksheet.getCell('B9').value = this.t('required') as string;
        worksheet.getCell('C9').value = this.t('required') as string;
        worksheet.getCell('D9').value = this.t('required') as string;
        worksheet.getCell('E9').value = this.t('required') as string;
        worksheet.getCell('F9').value = this.t('optional') as string;
        worksheet.getCell('G9').value = this.t('required') as string;
        worksheet.getCell('H9').value = `${this.t('required')}/${this.t('optional')}`;
        worksheet.getCell('I9').value = this.t('optional') as string;
        worksheet.getCell('J9').value = `${this.t('required')}/${this.t('optional')}`;
        worksheet.getCell('K9').value = `${this.t('required')}/${this.t('optional')}`;
        worksheet.getCell('L9').value = this.t('optional') as string;
        worksheet.getCell('M9').value = this.t('required') as string;
        worksheet.getCell('N9').value = this.t('required') as string;
        worksheet.getCell('O9').value = this.t('required') as string;
        worksheet.getCell('P9').value = this.t('required') as string;
        worksheet.getCell('Q9').value = this.t('required') as string;
        worksheet.getCell('R9').value = this.t('required') as string;
        worksheet.getCell('S9').value = this.t('optional') as string;

        // Detailed data requirements row
        // Note: The 'buot' prefix stands for Bulk User Onboard Template
        // Note: Replacing commas in lists below with ', ' in order for cell wrapping to occur on spaces

        worksheet.getCell('A10').value = this.t('buot-field-must-contain-3pl-warehouse') as string;

        worksheet.getCell('B10').value = this.t('buot-field-must-contain-one-of-following-options', {
            options: USER_TYPES.toString().replace(/,/g, ', ')
        }) as string;

        worksheet.getCell('C10').value = this.t('buot-field-must-contain-date-in-format-of', {
            dateFormat: PRM_DATE_FORMAT
        }) as string;

        worksheet.getCell('D10').value = this.t('buot-field-must-contain-date-in-format-of', {
            dateFormat: PRM_DATE_FORMAT
        }) as string;

        worksheet.getCell('H10').value = this.t('buot-if-field-is-provided-then-required-otherwise-optional', {
            fieldName: this.t('preferred-last-name')
        }) as string;

        worksheet.getCell('J10').value = this.t('buot-if-field-is-provided-then-required-otherwise-optional', {
            fieldName: this.t('preferred-first-name')
        }) as string;

        worksheet.getCell('K10').value =
            this.t('buot-if-provided-value-for-field-is-value-then-optional-otherwise-required', {
                fieldName: this.t('user-type'),
                fieldValue: UserTypes.ASSOCIATE
            })
            + " "
            + this.t('buot-if-provided-field-must-contain-valid-email');

        worksheet.getCell('L10').value = this.t('buot-if-provided-this-field-must-contain-one-of-following-options', {
            options: GENDER_TYPES.toString().replace(/,/g, ', ')
        }) as string;

        worksheet.getCell('M10').value = this.t('buot-field-must-contain-date-in-format-of', {
            dateFormat: PRM_DATE_FORMAT
        }) as string;
    }

    /**
     * Translates the 'Data' worksheet.
     *
     * @param workbook The workbook to translate.
     */
    #translateDataWorksheet(workbook: Workbook) {
        const worksheet = workbook.worksheets[2];

        // Data sheet name is not translated on purpose because it makes parsing difficult
        // For more info, see discussion here: https://code.amazon.com/reviews/CR-93211047/revisions/1#/comments

        this.#translateTitle(worksheet);
        this.#translateSectionHeaders(worksheet);
        this.#translateColumnNames(worksheet);
    }

    /**
     * Translates the worksheet's name.
     *
     * @param worksheet The worksheet to translate.
     * @param name The translated name for the worksheet.
     */
    #translateName(worksheet: Worksheet, name: string) {
        worksheet.name = name;
    }

    /**
     * Translates the worksheet's title.
     *
     * @param worksheet The worksheet to translate.
     */
    #translateTitle(worksheet: Worksheet) {
        worksheet.getCell('A1').value = this.t('bulk-user-onboard-template') as string;
    }

    /**
     * Translates the worksheet's section headers.
     *
     * @param worksheet The worksheet to translate.
     */
    #translateSectionHeaders(worksheet: Worksheet) {
        worksheet.getCell('A2').value = this.t('general') as string;
        worksheet.getCell('E2').value = this.t('personal-info') as string;
        worksheet.getCell('N2').value = this.t('identification') as string;
        worksheet.getCell('Q2').value = this.t('background-check') as string;
    }

    /**
     * Translates the worksheet's column names.
     *
     * @param worksheet The worksheet to translate.
     */
    #translateColumnNames(worksheet: Worksheet) {
        for (let i = 0; i < COLUMN_NAME_CELLS.length; i++) {
            worksheet.getCell(COLUMN_NAME_CELLS[i]).value = this.t(COLUMN_NAME_TRANSLATION_KEYS[i]);
        }
    }
}
