import React from 'react'
import { Link } from 'react-router-dom';

/**
 * Interface describing a BreadCrumb Item.
 */
export interface BreadCrumbItem {
  /**
   * Name that will show up in the Breadcrumbs.
   */
  tag: string
  /**
   * Path used for navigation.
   */
  path?: string
}

/**
 * Input parameters required to create BreadCrumbs component.
 */
interface BreadCrumbsProps {
  /**
   * A list of BreadCrumb Item to be displayed for a page.
   */
  breadcrumbItems: BreadCrumbItem[]
}

/**
 * Component for creating breadcrumbs to keep track of user locations within pages
 */
export const BreadCrumbs = (breadCrumbsProps: BreadCrumbsProps) => {
  const renderBreadCrumbItem = (breadCrumbItems: BreadCrumbItem[]) => {
    return breadCrumbItems.map((breadCrumbItem, index) => {
      const { tag, path } = breadCrumbItem
      return (
        <p key={index}>
          { path
            ? <Link to={path}>{tag}</Link>
            : <span>{tag}</span>
          }
          {/* Last breadcrumb doesn't need a '>' appended */}
          { index !== (breadCrumbItems.length - 1) &&
            <span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;</span>
          }
        </p>
      )
    })
  }

  return (
    <div className="col bread-crumbs" id="bread-crumbs">
      {renderBreadCrumbItem(breadCrumbsProps.breadcrumbItems)}
    </div>
  )
}
