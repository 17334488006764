import {TokenProviderConfig} from "@amzn/fitjava-script-client";


export const AFTX_3PL_TENANT_ID = "3pl"
export const SOX_TENANT_ID = "sox"

// Filled in from webpack
export const REGION = 'us-west-2'

export const NO_ACTIVE_WORKFLOW_FOUND = 'No active workflow found'

export const DEFAULT_LANG = 'en-US'

//FMUI Cookie Names
export const FMUI_LOCALE = 'fmui-locale'

//FMUI internal urls
export const ONBOARD_URL = '/onboard'
export const SINGLE_ONBOARD_URL = '/onboard/single'
export const BULK_ONBOARD_URL = '/onboard/bulk'
export const PRINCIPAL_LIST_URL = '/principal/'
export const PRINCIPAL_LIST_PATH = '/principal'
export const WORKFLOW_LIST_URL = '/workflow/'
export const MFA_REGISTRATION_URL = '/mfa-registration/'
export const MFA_REGISTRATION_PATH = '/mfa-registration'
export const AUTH_URL = '/auth'
export const PRINT_OTP_PATH = '/principal/print-otp'
export const EMPTY_PATH = ''
export const EMPTY_VALUE = ''
export const PRINCIPAL_BASELINE_PATH = '/principal/baseline/'

// Rendering
export const DROPDOWN_MAX_HEIGHT = '200px'

// CardItem statuses
export const STARTED = 'STARTED'
export const COMPLETED = 'COMPLETED'
export const FAILED = 'FAILED'

// Apollo Client
export const NETWORK_ONLY = 'network-only'

// MFA Registration
export const OTP_LENGTH = 16;
export const FIT_ENDPOINT = "https://beta.us-west-2.federated-auth.aft.a2z.com"  // Filled in from Webpack
export const AFTX_MENU_ENDPOINT = "" // Filled in from Webpack
export const MILO_REGISTRATION_FIT_TENANT = "17a102a3-f4cb-4dba-b3c2-406a8418fcf0"  // Filled in from Webpack
export const MILO_REGISTRATION_FIT_CLIENT_ID = "fmui-milo-integ" // Filled in from Webpack
export const MILO_REGISTRATION_ENDPOINT = "https://integ.milo.amazonoperations.com/ulmregistration-us-west-2"  // Filled in from Webpack

export const MILO_REGISTRATION_SUCCESS_STATUS = "success-milo";
export const MILO_REGISTRATION_ERROR_STATUS = "failure-milo";


export const PROXY_IDP_SUCCESS_STATUS = "success-proxyidp";

// Proxy IdP Error Codes
export const PIS_ERROR_OTP_USED = "otp_used";
export const PIS_ERROR_OTP_USED_TRANSLATION_KEY = "proxy-idp-error-code-otp-used";
export const PIS_ERROR_OTP_EXPIRED = "otp_expired";
export const PIS_ERROR_OTP_EXPIRED_TRANSLATION_KEY = "proxy-idp-error-code-otp-expired";
export const PIS_ERROR_OTP_NOT_FOUND = "otp_not_found";
export const PIS_ERROR_OTP_NOT_FOUND_TRANSLATION_KEY = "proxy-idp-error-code-otp-not-found";

// query param constants

export const ERROR = "error";
export const OTP = "otp";
export const STATUS = "status";
export const STATE = "state";
export const CODE = "code";

// Regex
export const UUID_REGEX = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
export const NUMERIC_REGEX = new RegExp('^[0-9]');
export const URL_SECURE_ALPHANUMERIC_REGEX = new RegExp('^[a-zA-Z0-9 !?.,:@]*$');

// AuthN
const FIT_CLIENT_DEV_AFTX_KEY = 'ulm-aftx.dev';
const FIT_CLIENT_DEV_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.doreiss-dev.alpha.aft.amazonoperations.app/auth',
    timeout: 2000,
};


const FIT_CLIENT_ALPHA_AFTX_KEY = 'ulm-aftx.alpha';
const FIT_CLIENT_ALPHA_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.alpha.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_BETA_AFTX_KEY = 'ulm-aftx.beta';
const FIT_CLIENT_BETA_AFTX: TokenProviderConfig = {
    tenantId: '4a09e756-286c-11ec-9621-0242ac130002',
    clientId: 'ulm-aftx-integ',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.beta.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_NA_AFTX_KEY = 'ulm-aftx.prod-na';
const FIT_CLIENT_PROD_NA_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-na',
    stage: 'prod',
    region: 'us-east-1',
    callbackUrl: 'https://ulm.na.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_EU_AFTX_KEY = 'ulm-aftx.prod-eu';
const FIT_CLIENT_PROD_EU_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-eu',
    stage: 'prod',
    region: 'eu-west-1',
    callbackUrl: 'https://ulm.eu.aft.amazonoperations.app/auth',
    timeout: 2000,
};

const FIT_CLIENT_PROD_FE_AFTX_KEY = 'ulm-aftx.prod-fe';
const FIT_CLIENT_PROD_FE_AFTX: TokenProviderConfig = {
    tenantId: '934e1c2c-1a34-466c-a622-e480e7bd4515',
    clientId: 'ulm-aftx-prod-fe',
    stage: 'prod',
    region: 'us-west-2',
    callbackUrl: 'https://ulm.fe.aft.amazonoperations.app/auth',
    timeout: 2000,
};

export const FIT_CLIENT_CONFIGS = new Map([
    [FIT_CLIENT_DEV_AFTX_KEY, FIT_CLIENT_DEV_AFTX],
    [FIT_CLIENT_ALPHA_AFTX_KEY, FIT_CLIENT_ALPHA_AFTX],
    [FIT_CLIENT_BETA_AFTX_KEY, FIT_CLIENT_BETA_AFTX],
    [FIT_CLIENT_PROD_NA_AFTX_KEY, FIT_CLIENT_PROD_NA_AFTX],
    [FIT_CLIENT_PROD_EU_AFTX_KEY, FIT_CLIENT_PROD_EU_AFTX],
    [FIT_CLIENT_PROD_FE_AFTX_KEY, FIT_CLIENT_PROD_FE_AFTX],
]);

export const FIT_CLIENT_CONFIG_KEY = "ulm-aftx.dev"  // Filled in from webpack
export const FIT_CLIENT_CONFIG_DEFAULT: TokenProviderConfig = FIT_CLIENT_BETA_AFTX;

export const SESSION_STORAGE_REDIRECT_BACK_PATH = 'redirect_back_path';
export const SESSION_STORAGE_REDIRECT_BACK_SEARCH = 'redirect_back_search';

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPE_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

export const PRM_DATE_FORMAT = "yyyy-MM-dd";

// Bulk User Onboard Template
export const DATA_SHEET_NAME = 'Data';

export const COLUMN_NAME_TRANSLATION_KEYS: string[] = [
    'warehouse',
    'user-type',
    'hire-start-date',
    'estimated-end-date',
    'legal-first-name',
    'legal-middle-name',
    'legal-last-name',
    'preferred-first-name',
    'preferred-middle-name',
    'preferred-last-name',
    'email',
    'gender',
    'date-of-birth',
    'national-id-country',
    'national-id-type',
    'national-id',
    'vendor-name',
    'vendor-ref-number',
    'exception-id'
];

export const COLUMN_NAME_CELLS = [
    'A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3',
    'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3'
];
