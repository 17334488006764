import React from 'react'
import { Icon } from '@amzn/alchemy-components-react'
import { CardItem } from './card-item'

/**
 * Input parameters required to create SummaryCard component.
 */
interface SummaryCardProps {
  /**
   * The unique key (i.e. workflowId, principalArn, etc.) for the card (used to determine what card to expand/collapse)
   */
  cardKey: string

  /**
   * Header to be displayed.
   */
  summaryCardHeader: string

  /**
   * A list of Items to be displayed for summary card.
   */
  summaryCardItems: CardItem[]

  /**
   * Element used to display what actions can be performed against the item
   */
  actionComponent: React.ReactNode

  /**
   * Element used to display the overall status of the item (optional)
   */
  statusComponent?: React.ReactNode

  /**
   * Function used to expand or collapse the card (optional)
   */
  toggleExpand?: (cardKey: string) => void

  /**
   * Flag used to determine if the card is expanded or collapsed (optional)
   */
  expanded?: boolean;
}

/**
 * Component for creating Summary card for list page.
 */
export const SummaryCard = (summaryCardProps: SummaryCardProps) => {
  const expandable: boolean = summaryCardProps.toggleExpand !== undefined && summaryCardProps.expanded !== undefined;

  const renderSummaryCardItem = (summaryCardItems: CardItem[]) => {
    return summaryCardItems.map((summaryCardItem, index) => {
      return (
        <div className="col-lg-4 overflow-auto" key={index}>
          <p className="attribute-name">{summaryCardItem.attributeName}</p>
          {Array.isArray(summaryCardItem.attributeValue) ? (
            <p>
              {summaryCardItem.attributeValue.map((value, index) => (
                <span key={index}>
                  {value}
                  <br />
                </span>
              ))}
            </p>
          ) : (
            <p>{summaryCardItem.attributeValue}</p>
          )}
        </div>
      )
    })
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    //Using CTRL key to expand
    if (event.key === 'Control') {
      summaryCardProps.toggleExpand!(summaryCardProps.cardKey!);
    }
  }

  return (
    <>
      <div className={`col mt-1 px-2 py-1 b-background`} id="summary-card">
        <div className="row subheading align-items-center mb-0" id="summary-card-header">
          <div
              className="col"
              id="summary-card-title"
              onClick={expandable ? () => summaryCardProps.toggleExpand!(summaryCardProps.cardKey) : undefined}
          >
            {expandable &&
              <div
                className="summary-card-expand-icon"
                id="summary-card-expand-icon"
                role="button"
                onKeyDown={(event) => handleKeyDown(event)}
                tabIndex={0}
              >
                <Icon className="expandable-icon" icon={summaryCardProps.expanded ? "chevronDown" : "chevronRight"} />
              </div>
            }
            {summaryCardProps.summaryCardHeader}

          </div>
          {summaryCardProps.statusComponent && (
              <div className="col-auto d-flex justify-content-end align-items-start" id="summary-card-status">
                {summaryCardProps.statusComponent}
              </div>
          )}
          <div className="col-auto" id="summary-card-action">
            {summaryCardProps.actionComponent}
          </div>
        </div>
        {(!expandable || summaryCardProps.expanded) &&
          <div className="row flex-wrap-reverse justify-content-between" id="summary-card-data">
            <div className="col-lg-12 row" id="summary-card-items">
              {renderSummaryCardItem(summaryCardProps.summaryCardItems)}
            </div>
          </div>
        }
      </div>
    </>
  )
}
