import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as CONSTANTS from 'src/common/constants';
import { isEmptyString } from 'src/common/functions';
import { useAuth } from 'src/components/auth/auth-provider';
import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "@amzn/alchemy-components-react";
import { logger } from "src/logger";
import * as KatalMetrics from "@amzn/katal-metrics";
import initialMetricsPublisher from "src/metrics";
import {
    AUTH_URL,
    BULK_ONBOARD_URL, CODE,
    EMPTY_PATH, EMPTY_VALUE, ERROR, MFA_REGISTRATION_PATH, NUMERIC_REGEX,
    ONBOARD_URL, OTP,
    PRINCIPAL_BASELINE_PATH, PRINCIPAL_LIST_PATH, PRINT_OTP_PATH,
    SINGLE_ONBOARD_URL, STATE, STATUS, URL_SECURE_ALPHANUMERIC_REGEX, UUID_REGEX
} from "src/common/constants";
import {UserTypes} from "src/common/enums";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('page', 'auth'),
]
// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const authMetricPublisher = initialMetricsPublisher.newChildActionPublisherForMethod('auth', additionalMetricsContext);


/**
 * Validates the url path which is retrieved from session storage
 * @param urlPath
 * @return true or false {@link boolean}.
 */
const validateUrlPath = (urlPath: string): boolean => {

    if (urlPath.startsWith(PRINCIPAL_BASELINE_PATH)) {
        return validateBaseliningUrlPath(urlPath);
    }

    const allowedUrlPath: string[] = [
        EMPTY_PATH,
        AUTH_URL,
        ONBOARD_URL,
        SINGLE_ONBOARD_URL,
        BULK_ONBOARD_URL,
        PRINCIPAL_LIST_PATH,
        PRINT_OTP_PATH,
        MFA_REGISTRATION_PATH
    ];

    return allowedUrlPath.indexOf(urlPath) > -1 || (allowedUrlPath + '/').indexOf(urlPath) > 1;
}

/**
 * Validates the baselining url path which is retrieved from session storage
 * @param urlPath
 * @return true or false {@link boolean}.
 */
const validateBaseliningUrlPath = (baseliningUrlPath: string): boolean => {

    const pathSplits = baseliningUrlPath.split('/');

    // system currently supports baselining for associate, secondary and primary admins
    if (pathSplits[3] !== UserTypes.ASSOCIATE.toLowerCase() &&
        pathSplits[3] !== UserTypes.USER_ADMIN_SECONDARY.toLowerCase() &&
        pathSplits[3] !== UserTypes.USER_ADMIN_PRIMARY.toLowerCase()) {
        return false;
    }

    // verifying site length
    return pathSplits[4].length == 4;

}

const validateUrlQueryParams = (urlQueryParams: URLSearchParams): boolean => {
    let validationResult = true;

    for (const [key, value] of urlQueryParams) {
        validationResult = validationResult && validateQueryParamValue(key, value);
        if (!validationResult) {
            logger.error(`Validation of query param: ${key} failed as it contains ${value}.`)
        }
    }

    return validationResult;
}

const validateQueryParamValue = (key: string, value: string): boolean => {
    switch (key) {
        case EMPTY_VALUE:
            return true;
        case OTP:
            return NUMERIC_REGEX.test(value)
        case ERROR:
        case STATUS:
        case STATE:
            return URL_SECURE_ALPHANUMERIC_REGEX.test(value)
        case CODE:
            return UUID_REGEX.test(value)
        default:
            return false;
    }
}

export const AuthPage = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [authCode] = useState<string>(searchParams.get('code') || '');
    const auth = useAuth();

    useEffect(() => {
        if (isEmptyString(authCode)) {
            const fromState = location.state as {from: Location};
            const redirectFromPath = fromState?.from?.pathname || '/'; // Redirect back to where user visits or home page
            const redirectFromSearch = fromState?.from?.search || '';
            sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_PATH, redirectFromPath);
            sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_SEARCH, redirectFromSearch);
            const redirectUrl = auth.getAuthUrl();
            logger.info('No auth code present, redirecting to FIT login page: ' + redirectUrl);
            authMetricPublisher.publishCounterMonitor('auth-code-redirect.SUCCESS', 1);
            window.location.href = redirectUrl;
            return;
        }
        auth.storeAuthCode(authCode);
        logger.info(`Exchanging Auth Code ${authCode} for FIT Token.`)
        authMetricPublisher.publishCounterMonitor('fit-get-token', 1);
        auth.getToken()
            .then((response) => {
                logger.info('User is authenticated');
                auth.setAuthState(true);

                // Redirect back to original endpoint or the root path
                const redirectBackPath = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_PATH) || "/";
                const redirectBackSearch = sessionStorage.getItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_SEARCH) || '';
                const searchParamsFromStorage = new URLSearchParams(redirectBackSearch);

                if (!validateUrlPath(redirectBackPath) ||
                    !validateUrlQueryParams(searchParamsFromStorage)) {
                    authMetricPublisher.publishCounterMonitor('url-validation.FAILURE', 1);
                    logger.info(`UrlValidation failed for redirectBackPath: ${redirectBackPath} and 
                    searchParamsFromLocalStorage: ${searchParamsFromStorage}, redirecting to base path`);
                    window.location.href = '/';
                    return;
                }

                logger.info("Redirecting to " + redirectBackPath + " with parameters: " + redirectBackSearch);
                sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_PATH, '');
                sessionStorage.setItem(CONSTANTS.SESSION_STORAGE_REDIRECT_BACK_SEARCH, '');
                authMetricPublisher.publishCounterMonitor('fit-get-token.SUCCESS', 1);
                window.location.href = `${redirectBackPath}${redirectBackSearch}`;
                return;
            })
            .catch((error) => {
                authMetricPublisher.publishCounterMonitor('fit-get-token.ERROR', 1);
                logger.error(`Failed to get token from FIT due to ${JSON.stringify(error)}`);
            });
    }, []);
    return (
        <>
            <Modal open={true} header={t('authenticating')}>
                <div className="row justify-content-center">
                    <Spinner size="lg" />
                </div>
            </Modal>
        </>
    );
}
