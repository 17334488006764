import { Alert } from "@amzn/alchemy-components-react";
import React from "react";
import { AlertType } from "src/common/enums";

export interface AlertBarProps {
    id?: string,
    result: AlertType,
    header: string,
    message: string | undefined,
    dismissible?: boolean,
    reset?: CallableFunction
}

/**
 * Dismissible Alert Bar displayed at the bottom of the page.
 *
 * @param props @type{AlertBarProps}
 */
export const AlertBar = (props: AlertBarProps) => {

    /**
     * Sets the message to null and calls the reset callable.
     */
    const handleDismiss = () => {
        if (props.reset) {
            props.reset();
        }
    }

    return (
        <>
            <Alert
                className="fixed-bottom mb-3"
                dismissible={props.dismissible != undefined ? props.dismissible : true}
                type={props.result}
                icon={props.result == AlertType.success ? "checkSolid" : "alert"}
                icon-size="lg"
                header={props.header}
                message={props.message}
                onDismiss={handleDismiss}
                id={props.id ? props.id : "fmui-alert-bar"}
            />
        </>
    )
}