import React, { useEffect, useState } from "react";
import { Button, Modal } from '@amzn/alchemy-components-react';
import { useTranslation } from "react-i18next";
import { AlertType } from "src/common/enums";
import {
    GET_PRINCIPAL_SCOPES_QUERY,
    REMOVE_SCOPE_MUTATION
} from "src/common/gql-operations";
import { useLazyQuery, useMutation } from "@apollo/client";
import { NETWORK_ONLY } from "src/common/constants";
import { AlertBar } from "src/components/alert-bar";
import { logger } from "src/logger";
import { GetPrincipalScopesData, GetPrincipalScopesVariables } from "src/models/get-principal-scopes";
import { RemoveScopeData, RemoveScopeVariables } from "src/models/remove-scope";

import initialMetricsPublisher from 'src/metrics';
import * as KatalMetrics from "@amzn/katal-metrics";

const cloudWatchDimensions = [
    new KatalMetrics.Metric.String('modal', 'remove-scope'),
]

// @ts-ignore
const additionalMetricsContext = new KatalMetrics.Context({cloudWatchDimensions});
const graphqlClientMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('graphql-client', additionalMetricsContext);
const buttonsMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod('buttons', additionalMetricsContext);

interface RemoveScopeModalProps {
    open: boolean,
    setOpen: CallableFunction,
    principalArn: string,
    setPrincipalArn: CallableFunction,
    tenantId: string,
    setTenantId: CallableFunction,
    scope: string,
    setScope: CallableFunction,
    userType: string,
    setUserType: CallableFunction,
    fullName: string,
    setFullName: CallableFunction
}

/**
 * Modal component used to ask for confirmation before removing a scope for a user.
 * @param removeScopeModalProps the modal properties
 */
export const RemoveScopeModal = (removeScopeModalProps: RemoveScopeModalProps) => {
    const {t} = useTranslation();

    const [alertBarType, setAlertBarType] = useState<AlertType>();
    const [alertBarHeader, setAlertBarHeader] = useState<string>();
    const [alertBarMsg, setAlertBarMsg] = useState<string>();

    const [onOpenCalled, setOnOpenCalled] = useState<boolean>(false);
    const [onCloseCalled, setOnCloseCalled] = useState<boolean>(false);

    const [scopes, setScopes] = useState<string[] | null>(null);

    const [ok, setOk] = useState<boolean>(false);

    // Calls PCS (via FMUI GraphQL back-end) to remove scope for selected principal
    const [removeScope, {}] =
        useMutation<RemoveScopeData, RemoveScopeVariables>(REMOVE_SCOPE_MUTATION, {
            variables: {
                removeScopeInput: {
                    tenantId: removeScopeModalProps.tenantId,
                    principalArn: removeScopeModalProps.principalArn,
                    scope: removeScopeModalProps.scope,
                    userType: removeScopeModalProps.userType
                }
            },
            onCompleted: data => {
                logger.info("Call to remove scope succeeded!", data);
                graphqlClientMetricsPublisher.publishCounterMonitor('remove-scope.SUCCESS', 1);
                showSuccessAlert(data.removeScope.workflowId ? t('scope-removed-and-offboarding-initiated') : t('scope-removed'), "");
                setOk(true);
            },
            onError: error => {
                logger.error("Call to remove scope failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('remove-scope.ERROR', 1);
                showErrorAlert(t('failed-to-remove-scope'), error.message);
                setOk(true);
            }
        });

    // Calls PCS (via FMUI GraphQL back-end) to get principal scopes by principalArn
    const [getPrincipalScopes, {}] =
        useLazyQuery<GetPrincipalScopesData, GetPrincipalScopesVariables>(GET_PRINCIPAL_SCOPES_QUERY, {
            variables: {
                getPrincipalScopesInput: {
                    tenantId: removeScopeModalProps.tenantId,
                    principalArn: removeScopeModalProps.principalArn
                }
            },
            fetchPolicy: NETWORK_ONLY, // don't cache
            notifyOnNetworkStatusChange: true, // necessary for onCompleted to be called after every poll
            onCompleted: data => {
                logger.info("Retrieved data from getPrincipalScopes.", data);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.SUCCESS', 1);
                setScopes(data.getPrincipalScopes.scopes);
            },
            onError: error => {
                logger.error("Call to getPrincipalScopes failed!", error);
                graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.ERROR', 1);
                showErrorAlert(t('failed-to-get-principal-scopes'), error.message);
                setOk(true);
            }
        });

    useEffect(() => {
        // Check if modal opened
        if (onOpenCalled) {
            callGetPrincipalScopes();
        }
    }, [onOpenCalled]);

    useEffect(() => {
        // Check if modal closed
        if (onCloseCalled) {
            resetProps();
        }
    }, [onCloseCalled]);

    /**
     * Resets the modal properties (this is necessary since modal is re-used for every principal on the 'Users' page).
     */
    const resetProps = () => {

        resetAlertBar();

        if (removeScopeModalProps.open) {
            // Set to false so modal can be opened again
            // Note: This triggers onClose again which is why onCloseCalled hook is needed to ignore this dup event
            removeScopeModalProps.setOpen(false);
        }

        setScopes(null);
        setOk(false);
    }

    /**
     * Calls to remove scope.
     */
    const callRemoveScope = () => {
        logger.info("Calling removeScope for principalArn: " + removeScopeModalProps.principalArn);
        graphqlClientMetricsPublisher.publishCounterMonitor('remove-scope.INVOCATION', 1);
        removeScope();
    }

    /**
     * Calls to get all the active scopes for a principal.
     */
    const callGetPrincipalScopes = () => {
        logger.info("Calling getPrincipalScopes for principalArn: " + removeScopeModalProps.principalArn);
        graphqlClientMetricsPublisher.publishCounterMonitor('get-principal-scopes.INVOCATION', 1);
        getPrincipalScopes();
    }

    /**
     * Shows a success via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showSuccessAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.success);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Shows an error via the AlertBar.
     *
     * @param header the header for the AlertBar.
     * @param msg the message for the AlertBar.
     */
    const showErrorAlert = (header: string, msg: string) => {
        setAlertBarType(AlertType.error);
        setAlertBarHeader(header)
        setAlertBarMsg(msg);
    }

    /**
     * Resets the AlertBar since it's re-usable.
     */
    const resetAlertBar = () => {
        setAlertBarType(undefined);
        setAlertBarHeader(undefined);
        setAlertBarMsg(undefined);
    }

    /**
     * Renders the confirmation messages.
     */
    const renderConfirmationMessage = () => {
        if (ok) {
            return (
                <div className="d-inline">
                    {renderOKButton()}
                </div>
            )
        }

        if (scopes === null) {
            return (
                <div className="d-inline">
                    <p>{t('loading')}</p>
                </div>
            )
        } else if (scopes.indexOf(removeScopeModalProps.scope + ":" + removeScopeModalProps.userType) === -1) {
            return (
                <div className="d-inline">
                    <p>{t('confirmation-message-no-access', {
                        fullName: removeScopeModalProps.fullName,
                        scope: removeScopeModalProps.scope
                    })}</p>
                </div>
            )
        } else if (scopes.length === 1) {
            return (
                <div className="d-inline">
                    <p>{t('confirmation-message-one-scope-left', {
                        fullName: removeScopeModalProps.fullName,
                        scope: removeScopeModalProps.scope
                    })}</p>
                    {renderConfirmCancelButtons()}
                </div>
            )
        } else {
            return (
                <div className="d-inline">
                    <p>{t('confirmation-message-more-scopes-left', {
                        fullName: removeScopeModalProps.fullName,
                        scope: removeScopeModalProps.scope,
                        scopes: scopes.map(scope => {
                            return <li>{scope.split(":")[0]}</li>;
                        })
                    })}</p>
                    {renderConfirmCancelButtons()}
                </div>
            )
        }
    }

    /**
     * Renders the Confirm & Cancel buttons.
     */
    const renderConfirmCancelButtons = () => {
        return (
            <div className="row d-flex justify-content-center">
                <Button id="confirm-button"
                        size="lg"
                        label={t('confirm')}
                        onClick={() => {
                            buttonsMetricsPublisher.publishCounterMonitor('remove-scope-confirm.CLICKS', 1);
                            callRemoveScope();
                        }}
                />
                <Button id="cancel-button"
                        size="lg"
                        label={t('cancel')}
                        onClick={() => {
                            buttonsMetricsPublisher.publishCounterMonitor('remove-scope-cancel.CLICKS', 1);
                            setOnOpenCalled(false);
                            setOnCloseCalled(true);
                        }}
                />
            </div>
        )
    }

    /**
     * Renders the OK button. After clicking OK, this modal will be closed.
     * TODO: Eventually we want to remove this OK button and automatically close the modal after user confirms or cancels.
     *  Alert bar should stick to the screen. SIM: https://sim.amazon.com/issues/AFTI-392
     */
    const renderOKButton = () => {
        return (
            <div className="row d-flex justify-content-center">
                <Button id="ok-button"
                        size="lg"
                        label={t('ok')}
                        onClick={() => {
                            setOnOpenCalled(false);
                            setOnCloseCalled(true);
                            location.reload();
                        }}
                />
            </div>
        )
    }

    return (
        <Modal id="remove-scope-modal"
               className="mb-1"
               header={t('remove-scope')}
               headerDescription={t('user-and-site', {
                   fullName: removeScopeModalProps.fullName,
                   scope: removeScopeModalProps.scope
               })}
               open={removeScopeModalProps.open}
               onOpen={() => {
                   setOnOpenCalled(true);
                   setOnCloseCalled(false);
               }}
               onClose={() => {
                   setOnOpenCalled(false);
                   setOnCloseCalled(true);
               }}
        >
            <div className="container-fluid">
                <div className="row">
                    <div id="confirmation-message" className="col d-inline">
                        {renderConfirmationMessage()}
                    </div>
                </div>
            </div>
            {alertBarHeader &&
                <AlertBar
                    id="remove-scope-modal-alert-bar"
                    result={alertBarType!}
                    dismissible={AlertType.error !== alertBarType}
                    header={alertBarHeader!}
                    message={alertBarMsg!}
                    reset={resetAlertBar}
                />
            }
        </Modal>
    )
}