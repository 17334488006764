export enum AlertType {
    info = 'info',
    error = 'error',
    success = 'success',
    warning = 'warning',
}

export enum FMUIPageTypes {
    ONBOARD = 'ONBOARD',
    PRINCIPAL_LIST = 'PRINCIPAL_LIST',
    WORKFLOW_LIST = 'WORKFLOW_LIST',
    MFA_REGISTRATION = 'MFA_REGISTRATION',
    DEFAULT_ERROR_PAGE = 'DEFAULT_ERROR_PAGE',
}

export enum UserTypes {
    SYSTEM_ADMIN = 'SYSTEM_ADMIN',
    USER_ADMIN_PRIMARY = 'USER_ADMIN_PRIMARY',
    USER_ADMIN_SECONDARY = 'USER_ADMIN_SECONDARY',
    ABBM_PRIMARY = 'ABBM_PRIMARY',
    ABBM_SECONDARY = 'ABBM_SECONDARY',
    ASSOCIATE = 'ASSOCIATE'
}

export const USER_TYPES : string[] = [
    UserTypes.ASSOCIATE,
    UserTypes.USER_ADMIN_PRIMARY,
    UserTypes.USER_ADMIN_SECONDARY,
    UserTypes.ABBM_PRIMARY,
    UserTypes.ABBM_SECONDARY,
    UserTypes.SYSTEM_ADMIN
]

export enum GenderTypes {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    NOT_DISCLOSED = 'NOT_DISCLOSED',
    UNKNOWN = 'UNKNOWN'
}

export const GENDER_TYPES : string[] = [
    GenderTypes.MALE,
    GenderTypes.FEMALE,
    GenderTypes.NOT_DISCLOSED,
    GenderTypes.UNKNOWN
]

export enum BaseliningStates {
    NOT_PERFORMED = 'NOT_PERFORMED',
    ALLOWED = 'ALLOWED',
    DENIED = 'DENIED'
}

export const BASELINING_STATES : string[] = [
    BaseliningStates.NOT_PERFORMED,
    BaseliningStates.ALLOWED,
    BaseliningStates.DENIED
]

export enum OtpRetrievalStatus {
    COMPLETED,
    FAILED
}

export enum WorkflowCurrentStep {
    TERMINATION_INITIATED = 'termination_initiated',
    BASELINING_BATCH_PROCESSED = 'baselining_batch_processed'
}

export enum WorkflowType {
    AFTX_3PL_ASSOCIATE_ONBOARDING = 'aftx_3pl_associate_onboarding',
    AFTX_3PL_BULK_USER_ONBOARDING = 'aftx_3pl_bulk_user_onboarding',
    AFTX_3PL_USER_ADMIN_ONBOARDING  = 'aftx_3pl_user_admin_onboarding',
    USER_TERMINATION = 'user_termination',
    BASELINING_ASSOCIATE = 'baselining_associate',
    BASELINING_USER_ADMIN_PRIMARY = 'baselining_user_admin_primary',
    BASELINING_USER_ADMIN_SECONDARY = 'baselining_user_admin_secondary',
    BASELINING_ALL_ASSOCIATE = 'baselining_all_associate',
    BASELINING_ALL_USER_ADMIN_PRIMARY = 'baselining_all_user_admin_primary',
    BASELINING_ALL_USER_ADMIN_SECONDARY = 'baselining_all_user_admin_secondary',
    MFA_REGISTRATION = 'mfa_registration',
    BASELINING_ALL_PERMISSIONS = 'baselining_all_permissions',
    BASELINING_PERMISSION = 'baselining_permission',
}

export enum WorkflowStatus {
    ALL = 'ALL',
    ACTIVE = 'ACTIVE',
    COMPLETED = 'COMPLETED',
    SUCCEEDED = 'SUCCEEDED',
    FAILED = 'FAILED'
}

export enum WorkflowState {
    ACTIVE = 'active',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export enum BASELINING_OPERATIONS {
    REMOVE = 'REMOVE',
    KEEP = 'KEEP'
}

export enum BASELINING_PERMISSIONS_OPERATIONS {
    NONE = 'NONE',
    BEGINNER = 'BEGINNER',
    INTERMEDIATE = 'INTERMEDIATE',
    EXPERT = 'EXPERT',
    ADMIN = 'ADMIN'
}

export const ABBREVIATION_TO_PERMISSION_MAP : Map<string, BASELINING_PERMISSIONS_OPERATIONS> =
    new Map<string, BASELINING_PERMISSIONS_OPERATIONS>([
    ['N', BASELINING_PERMISSIONS_OPERATIONS.NONE],
    ['B', BASELINING_PERMISSIONS_OPERATIONS.BEGINNER],
    ['I', BASELINING_PERMISSIONS_OPERATIONS.INTERMEDIATE],
    ['E', BASELINING_PERMISSIONS_OPERATIONS.EXPERT],
    ['A', BASELINING_PERMISSIONS_OPERATIONS.ADMIN]]);

export const PERMISSION_TO_ABBREVIATION_MAP : Map<BASELINING_PERMISSIONS_OPERATIONS, string> =
    new Map<BASELINING_PERMISSIONS_OPERATIONS, string>([
        [BASELINING_PERMISSIONS_OPERATIONS.NONE, 'N'],
        [BASELINING_PERMISSIONS_OPERATIONS.BEGINNER, 'B'],
        [BASELINING_PERMISSIONS_OPERATIONS.INTERMEDIATE, 'I'],
        [BASELINING_PERMISSIONS_OPERATIONS.EXPERT, 'E'],
        [BASELINING_PERMISSIONS_OPERATIONS.ADMIN, 'A']]);

export enum OnboardType {
    SINGLE = 'single',
    BULK = 'bulk'
}

export enum VendorName {
    ACCURATE = 'Accurate',
    FIRST_ADVANTAGE = 'First Advantage',
    TRUE_SCREEN = 'True Screen'
}