import React from "react";
import {Spinner} from "@amzn/alchemy-components-react";

export const FallbackSpinner = () =>  {
    return (
        <div className="fallback-spinner-box">
            <Spinner
                className="fallback-spinner"
                id="fallback-spinner"
                label="Loading..."
                size="3"
            />
        </div>
    )
}