import { TokenProvider, TokenProviderConfig } from '@amzn/fitjava-script-client';
import {FIT_CLIENT_CONFIG_KEY, FIT_CLIENT_CONFIGS, FIT_CLIENT_CONFIG_DEFAULT } from "src/common/constants";
import {logger} from "src/logger";

export function getTokenProviderConfig(): TokenProviderConfig {
    let fitConfig: TokenProviderConfig = FIT_CLIENT_CONFIG_DEFAULT
    if (FIT_CLIENT_CONFIGS.has(FIT_CLIENT_CONFIG_KEY)) {
        // @ts-ignore
        fitConfig = FIT_CLIENT_CONFIGS.get(FIT_CLIENT_CONFIG_KEY);
    }
    return fitConfig;
}

export const tokenProviderInstance = new TokenProvider(getTokenProviderConfig(), logger);
