import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import ICU from 'i18next-icu';
import LanguageDetector from 'i18next-browser-languagedetector';
import languageMap from '@amzn/katal-localization/webpack-loader!';
import { DEFAULT_LANG, FMUI_LOCALE } from "src/common/constants";

// Whether initialization is complete
let isInitialized = false;

// If initialization is complete, the last error, if any
let initializationError: string | undefined;

type I18nCallback = (err?: string) => void;

// Registered callbacks for when i18n initialization completes
let i18nInitializationCallbacks: I18nCallback[] = [];

const detectionOption = {
    // order from where user language should be detected
    order: ['cookie', 'htmlTag'],

    // keys or params to lookup language from
    lookupCookie: FMUI_LOCALE,
}

i18n.use(Backend)
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            debug: process.env.NODE_ENV !== 'production', // logs info level to console output when true
            fallbackLng: DEFAULT_LANG, // language to use if translations in user language are not available
            interpolation: {
                escapeValue: false, // escape passed in values to avoid XSS injection (not needed for react as it escapes by default)
            },
            load: 'currentOnly', // strategy to define which language codes to lookup
            backend: {
                loadPath: (languages: string[]) => {
                    return `/${languageMap[languages[0]]}`
                },
                crossDomain: true,
            },
            detection: detectionOption, // language detector
        }, (err?: string) => {
            initializationError = err;
            isInitialized = true;
            i18nInitializationCallbacks.forEach(callback => {
                callback(initializationError);
            });
            i18nInitializationCallbacks.length = 0;
        }
    );

/**
 * Helper function to run the given callback to report the status of translation initialization.
 *
 * If translation is already initialized the callback will run immediately, otherwise it will run
 * when translation initialization is complete.
 *
 * This helper function fills a gap in the i18next library, where it doesn't report the initialization
 * status through any of its own events or status flags.
 */
const i18nWhenReady = (callback: I18nCallback) => {
    if (isInitialized) {
        callback(initializationError);
    } else {
        i18nInitializationCallbacks.push(callback);
    }
};

export { i18nWhenReady };
export default i18n;
