import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app';
import AppInitWrapper from './components/app-init-wrapper';
import {FallbackSpinner} from "src/components/fallback-spinner";
import './i18n';
import './logger';
import './index.scss';

// The Suspense component causes React to display the fallback spinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<FallbackSpinner/>}>
        <App />
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root')
);
