import React, { useEffect, useState } from 'react';
import { CookiesManager } from "src/common/cookies-manager";
import { Languages } from "src/common/languages"
import { DEFAULT_LANG, DROPDOWN_MAX_HEIGHT, FMUI_LOCALE } from "src/common/constants";
import i18n from "i18next";
import { Dropdown } from "@amzn/alchemy-components-react";

export const LanguageDropdown = () => {

    // TODO: Once supported languages are the same for AFTX and FMUI, add logic to first check if fcmenu-locale cookie is present, and if it is, use it to set the locale
    const [lang, setLang] = useState(
        CookiesManager.getCookie(FMUI_LOCALE) ? CookiesManager.getCookie(FMUI_LOCALE) : DEFAULT_LANG
    );

    const [dir, setDir] = useState<string>();

    /**
     * Set the page direction (triggered one time after the component mounts).
     */
    useEffect(() => {
        // If initial language on page load is rtl and language is changed to one that is ltr, elements are not aligned
        // correctly without setTimeout() wrapping the setDir() call below. This puts the anonymous function on the JS
        // event queue to run immediately after the currently running React stack frame has completed.
        setTimeout(() => {
            setDir(i18n.dir());
            document.documentElement.lang = i18n.language;
        }, 0);
    }, []);

    /**
     * Set the root element direction and update Sidenav button alignment (triggered when the direction is changed).
     */
    useEffect(() => {
        if (dir) {
            document.documentElement.dir = dir;

            // When the direction is set to rtl, the hamburger expand/collapse sidenav button is not aligned correctly
            // TODO: Remove this when Alchemy team fixes this bug
            updateSidenavBtnAlignment(dir);
        }
    }, [dir]);

    /**
     * Change the language and direction for the whole app (triggered whenever the Dropdown selection changes).
     *
     * @param lang The selected language.
     */
    const onLanguageChange = async (lang: string) => {
        setLang(lang);
        CookiesManager.setCookie(FMUI_LOCALE, lang);
        document.documentElement.lang = lang;
        await i18n.changeLanguage(lang);

        setDir(i18n.dir());
    }

    /**
     * Update the alignment of the button that expands/collapses the AppWrapper Sidenav based on the language direction.
     *
     * @param dir The language direction.
     */
    const updateSidenavBtnAlignment = (dir: string) => {
        const wrapper = document.querySelector('#fmui-appwrapper');

        if (wrapper && wrapper.shadowRoot) {
            const sidenav = wrapper.shadowRoot.querySelector('#fmui-appwrapper-sidenav');

            if (sidenav && sidenav.shadowRoot) {
                const trigger = sidenav.shadowRoot.querySelector('#fmui-appwrapper-sidenav-trigger') as HTMLElement;

                // Check if direction is right-to-left
                if (dir === 'rtl') {
                    trigger.style.right = '0px';
                    trigger.style.left = 'unset';
                } else { // Direction is left-to-right
                    trigger.style.left = '0px';
                    trigger.style.right = 'unset';
                }
            }
        }
    }

    return (
        <>
            <Dropdown
                className="language-dropdown"
                id="language-dropdown"
                labelSrOnly={true}
                options={Languages}
                value={lang}
                maxHeight={DROPDOWN_MAX_HEIGHT}
                onChange={(e) => onLanguageChange(e.target.value)}
            />
        </>
    )
}