import React from 'react';
import { Button } from "@amzn/alchemy-components-react";
import { useTranslation } from "react-i18next";
import { signOut } from "src/common/util";

export const SignoutButton = () => {

    const {t} = useTranslation();

    return (
        <Button id="signout-button"
                className="mx-1"
                size="md"
                label={t('sign-out')}
                variant="action"
                onClick={signOut}
        />
    )

}