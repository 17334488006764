import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { PageProps } from "src/pages/page-interface";
import { BreadCrumbItem, BreadCrumbs } from "src/components/breadcrumb";
import { FMUIPageTypes } from "src/common/enums";
// @ts-ignore
import bwipjs from 'bwip-js';
import { logger } from "src/logger";
import { convertDateTimeToLocaleUTCFormat } from "src/common/util";

interface PrintOtpState {
    principalArn: string,
    fullName: string,
    otp: string,
    otpCreationDate: Date,
    otpExpirationDate: Date
}

export const PrintOtp = (props: PageProps) => {
    const {t} = useTranslation();

    const locale = document.documentElement.lang;   // e.g. en-US
    const location = useLocation<PrintOtpState>();

    const principalArn: string = location.state ? location.state.principalArn : '';
    const fullName: string = location.state ? location.state.fullName : '';
    const otp: string = location.state ? location.state.otp : '';
    const otpCreationDate: string = location.state ?
        convertDateTimeToLocaleUTCFormat(locale, location.state.otpCreationDate) : '';
    const otpExpirationDate: string = location.state ?
        convertDateTimeToLocaleUTCFormat(locale, location.state.otpExpirationDate) : '';

    useEffect(() => {
        // Print OTP is a subpage under Users page, so set Users nav item as active page
        props.setActivePage(FMUIPageTypes.PRINCIPAL_LIST);

        if (!location.state) {
            logger.error("Failed to load 'Print OTP' page since state is missing!");

            // Redirect to 'Users' page
            window.location.replace('/principal');
        } else {
            if (otp) {
                bwipjs.toCanvas('otp-barcode-canvas', {
                    bcid: 'datamatrix',        // Barcode type
                    text: otp,                 // Text to encode
                    scale: 5,                  // Scaling factor
                });
            }

            // Open print dialog
            // Note: Wrapping in setTimeout fixes this React error: "Uncaught Error: Should not already be working."
            setTimeout(() => window.print(), 0);
        }
    }, []);

    const getBreadcrumbs = (): BreadCrumbItem[] => {
        const breadcrumbs: BreadCrumbItem[] = [];

        breadcrumbs.push({tag: t('users'), path: "/principal"});
        breadcrumbs.push({tag: fullName}); // TODO: Add a path to this Breadcrumb that navigates the user back to the Users page with the selected User accordion opened
        breadcrumbs.push({tag: t('print-otp')});

        return breadcrumbs;
    };

    const renderPage = () => {
        if (location.state) {
            return (
                <div id="print-otp-page" className="container-fluid">
                    <div id="bread-crumbs-row" className="row">
                        <BreadCrumbs breadcrumbItems={getBreadcrumbs()}/>
                    </div>
                    <div className="row mb-4">
                        <div className="col-8 mx-auto">
                            <div className="d-flex flex-row justify-content-end mb-4">
                                <div className="d-flex flex-column pr-1">
                                    <b>{t('created')} </b>
                                    <b>{t('expires')} </b>
                                </div>
                                <div className="d-flex flex-column text-right">
                                    <span id="otp-creation-date">{otpCreationDate}</span>
                                    <span id="otp-expiration-date">{otpExpirationDate}</span>
                                </div>
                            </div>
                            <div className="text-center mb-2">
                                <b>{t('otp-for-associate')}</b>&nbsp;
                                <span id="associate-full-name">{fullName}</span>
                            </div>
                            <div className="text-center">
                                <canvas id="otp-barcode-canvas"/>
                            </div>
                            <div className="text-center mb-4">
                                <span id="otp-value">{otp}</span>
                            </div>
                            <div className="mb-1">
                                <b>{t('mfa-registration-instructions-header')}</b>
                            </div>
                            <div>
                                <ol>
                                    {/* TODO: remove `-alpha` from translation key when the finalized instructions are provided. */}
                                    <li>{t('mfa-registration-instruction-step-1-alpha')}</li>
                                    <li>{t('mfa-registration-instruction-step-2-alpha')}</li>
                                    <li>{t('mfa-registration-instruction-step-3-alpha')}</li>
                                    <li>{t('mfa-registration-instruction-step-4-alpha')}</li>
                                </ol>
                            </div>
                            <div>
                                <p>{t('mfa-registration-instructions-footer-alpha')}</p>
                            </div>
                        </div>
                    </div>
                    <div id="notes-row" className="row">
                        <div className="col">
                            <div className="text-center">
                                <i className="font-italic">{t('please-print-this-page-and-hand-it-to-the-associate')}</i>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        renderPage()
    );
}